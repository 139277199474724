// @ts-nocheck
import { Col, Form, Image, Row } from "react-bootstrap";
import "./BussinessWork.css";
import { Link } from "react-router-dom/dist";
import { Icon } from "@iconify/react/dist/iconify";
import styles from "./buisnessWork.module.css";
import { useTranslation } from "react-i18next";
const BussinessWork = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="" id="bussinesswork">
        <div className="container">
          <div className="flex flex-col gap-10">
            <div className={styles.title}>
              <h2> {t("home.TitleHowNojomWorks")} </h2>{" "}
            </div>{" "}
            <div className={styles.bussineswormain}>
              <Row className="sm:gap-5">
                <Col lg={4} md={4} xs={12}>
                  <div className={styles.bussinesworkdetail}>
                    <Image src="/Images/search.svg" />
                    <h3> {t("home.TitleSelectStar")} </h3>{" "}
                    <p>{t("home.TextSelectStar")}</p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col lg={4} md={4} xs={12}>
                  <div className={styles.bussinesworkdetail}>
                    <Image src="/Images/doc.svg" />
                    <h3>{t("home.TitleHireStar")}</h3>{" "}
                    <p>{t("home.TextHireStar")}</p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col lg={4} md={4} xs={12}>
                  <div className={styles.bussinesworkdetail}>
                    <Image src="/Images/plan.svg" />
                    <h3>{t("home.TitleLaunchYourCampaign")}</h3>{" "}
                    <p>{t("home.TextLaunchYourCampaign")}</p>{" "}
                  </div>{" "}
                </Col>
              </Row>{" "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BussinessWork;
