
import { Col, Image, Row } from 'react-bootstrap';
import './Aboutus.css';

import React from "react";


const WhyNojom = () => {
    return (
        <>
            <section className="whynojomsection">
                <div className="container">
                    <div className="whynojomcontent">
                        <h2>Why nojom?</h2>
                        <p>Nojom Provides All The Tools You Need To Control Campaign And Scale Your Business Successfully.</p>
                    </div>
                    <Row>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojomchaticon.svg' /></span>
                                <h3>Seamless <br /> Communication</h3>
                                <p>We prioritize transparent and efficient communication, facilitating genuine connections.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojompaymenticon.svg' /></span>

                                <h3>Secure Payment  <br /> Solutions</h3>

                                <p>nojom ensures worry-free transactions with our secure payment on delivery mechanism.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojomdiverceicon.svg' /></span>
                                <h3>Diverse Influencer <br /> Selection</h3>

                                <p>Explore a curated variety of influencers, ensuring a perfect match for every brand's unique identity and needs.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojomtrialicon.svg' /></span>
                                <h3>User-Friendly <br /> Experience</h3>

                                <p>Navigate our platform effortlessly with our easy-to-use mobile application.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >

        </>
    );
}

export default WhyNojom;
