// @ts-nocheck
import React, { useState, useTransition, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import SectionTitle from "./SectionTitle";
import { getAgentStore } from "./action";
import { jwtDecode } from "jwt-decode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "component/Loader/Loader";
import Sperarator from "./Seperator";
function UserMainMyYoutube({ id }) {
  const [userAgentStore, setUserAgentStore] = useState({
    data: [],
    page_no: 1,
    loading: true
  });
  const getUserAgentStore = async () => {
    setUserAgentStore({
      ...userAgentStore,
      loading: true
    });
    await getAgentStore(id, userAgentStore.page_no)
      .then((res) => {
        const data = jwtDecode(res.data.data);
        setUserAgentStore({
          ...userAgentStore,
          data: data,
          loading: false
        });
      })
      .catch(() => {
        setUserAgentStore({
          ...userAgentStore,
          loading: false
        });
      });
  };
  useEffect(() => {
    getUserAgentStore();
  }, [userAgentStore.page_no]);
  const { i18n } = useTranslation();
  const [_, startTransition] = useTransition();
  const handleNext = () => {
    startTransition(() => {
      setUserAgentStore((prevState) => ({
        ...prevState,
        page_no: prevState.page_no + 1
      }));
    });
  };
  const handlePrev = () => {
    if (userAgentStore?.page_no >= 2) {
      startTransition(() => {
        setUserAgentStore((prevState) => ({
          ...prevState,
          page_no: prevState.page_no - 1
        }));
      });
    }
  };
  const ItemsAmount = userAgentStore?.page_no * 6;
  console.log(userAgentStore?.data);
  if (userAgentStore?.data.length === 0) return null;
  return (
    <Fragment>
      <Sperarator />
      <section className="section">
        <SectionTitle
          title="YouTube"
          handleNext={handleNext}
          handlePrev={handlePrev}
          isNextDisabled={userAgentStore?.data?.count <= ItemsAmount}
          isPrevDisabled={userAgentStore?.page_no <= 1}
        />
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={24}
          className="w-full"
          breakpoints={{
            320: {
              spaceBetween: 20
            },
            768: {
              spaceBetween: 24
            }
          }}
        >
          {userAgentStore?.data?.data.slice(0, 3)?.map((item, index) => (
            <SwiperSlide className="!w-[288px] !h-[162px]" key={index}>
              <Link to={item?.link}>
                <div className="w-[288px] h-[162px] sm:w-full sm:h-full relative my-youtube-item flex justify-center items-center">
                  <Image
                    src={`${userAgentStore?.data.path}${item?.filename}`}
                    className="w-full h-full object-cover rounded-xl"
                    alt="store-image"
                  />
                  <div className="translate-y-[10%] translate-x-[10%] absolute z-[999]">
                    <Image
                      src={process.env.PUBLIC_URL + "/Images/youtube.svg"}
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="absolute top-0 left-0 w-full h-full layout rounded-xl"></div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </Fragment>
  );
}
export default UserMainMyYoutube;
