// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Image } from "react-bootstrap";
// import InfluencerProfile from './InfluencerProfile';
import { Icon } from "@iconify/react/dist/iconify";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Select from "react-select";
import InfluencerProfile from "../../HomePageComponent/FindtheBest/InfluencerProfile";
import "./Inflancers.css";
import {
  fetchCategories,
  fetchStarts,
  fetchCountry,
  fetchCity,
} from "../../HomePageComponent/FindtheBest/action";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Accordion from "react-bootstrap/Accordion";
function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button
      variant=""
      type="button"
      className="custom-accordian-button"
      // style={{ backgroundColor: isCurrentEventKey ? PINK : BLUE }}
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? (
        <Image src="/Images/arrowtop.svg" />
      ) : (
        <Image src="/Images/arrowbottom.svg" />
      )}
    </Button>
  );
}

// const sortOptions = [
//   { value: 'PRICEHIGHTOLOW', label: 'Price: high to low' },
//   { value: 'PRICELOWTOHIGH', label: 'Price: low to high' },
//   { value: 'FOLLOWERHIGHTOLOW', label: 'Number of follower: high to low' },
//   { value: 'FOLLOWERLOWTOHIGH', label: 'Number of follower: low to high' },
//   { value: 'NEWEST', label: 'Newest' },
// ]
const priceOptions = [
  { value: "0TO1000", label: "$0 - $1,000" },
  { value: "1000TO2500", label: "$1,000 - $2,500" },
  { value: "2500TO5000", label: "$2,500 - $5,000" },
  { value: "5000TO10000", label: "$5,000 - $10,000" },
  { value: "10000Plus", label: "$10,000" },
  // { value: 'custom', label: 'Custom' },
];
const Inflancers = () => {

  let history = localStorage.getItem("filterData");
  let AllData ;
  if (history) {
     AllData = JSON.parse(history);
  }
  const [starsList, setStarsList] = useState([]);

  const [categoryList, setCategoryList] = useState();
  const [selectedSortby, setSelectedSortBy] = useState();
  const [selectedCat, setSelectedCat] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState();
  const [searchString, setSearchString] = useState();
  const [imagePath, setImagePath] = useState("");
  const [starsCount, setStarsCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [Star, setStars] = useState([]);
  const [searchValue, setSearchValue] = useState(localStorage.getItem('search') || "");

  const [filterHistory, setFilterHistory] = useState(AllData || {});
  const [mathooqs, setmathooqs] = useState(AllData?.mawthooq_status || "");
  const [sort, setSort] = useState(AllData?.sort_by || "");
  const [categoryvalue, setcategoryValue] = useState(
    AllData?.categoryvalue || []
  );
  const [selectedPrices, setSelectedPrices] = useState(
    AllData?.price_range || []
  );


  // Items per page
  const itemsPerPage = 32;

  // Calculate total pages
  const totalPages = Math.ceil(starsCount / itemsPerPage);

  useEffect(() => {
    getStarsList();
    getCategoryList();
    // getcountry()
  }, [0]);

  const getCategoryList = async () => {
    try {
      const resp = await fetchCategories();

      if (resp?.data?.status) {
        const data = jwtDecode(resp.data.data);
        setCategoryList(
          data.data[0].services.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching categories", error);
      // Handle the error as needed
    }
  };

  const createFilters = (sort, cat, price, search, page) => {
    const formData = new FormData();

    if (sort) {
      formData.append("sort_by", sort);
    }
    if (cat) {
      formData.append("skill_id", cat);
    }

    if (price) {
      switch (price) {
        case "0TO1000":
          formData.append("price_range_start", 0);
          formData.append("price_range_end", 100);
          break;
        case "1000TO2500":
          formData.append("price_range_start", 1000);
          formData.append("price_range_end", 2500);
          break;
        case "2500TO5000":
          formData.append("price_range_start", 2500);
          formData.append("price_range_end", 5000);
          break;
        case "5000TO10000":
          formData.append("price_range_start", 5000);
          formData.append("price_range_end", 10000);
          break;
        case "10000Plus":
          formData.append("price_range_start", 10000);
          formData.append("price_range_end", "");
          break;
        default:
          break;
      }
    }
    if (search) {
      formData.append("search", search);
    }
    formData.append("limit", itemsPerPage);
    formData.append("page_no", 1);

    return formData;
  };

  const getStarsList = async (sort, cat, price, search, page, pageNumber) => {
    try {
      setIsLoading(true);
      const formData = handleFilter();
      //   for (var pair of formData.entries()) {
      //     console.log('formdata submit',pair[0] + ': ' + pair[1]);
      // }

    // return
    if(page){

      formData.set("page_no", page)
    } 
      const resp = await fetchStarts(formData);

      if (resp.data.status) {
        const data = jwtDecode(resp.data.data);
        setStarsList(data.agents);
        setImagePath(data.path);
        setStarsCount(data.user_count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching stars", error);
      // Handle the error as needed
    }
  };

  const handleChangeSort = (selectedOption) => {
    setSelectedSortBy(selectedOption);
    getStarsList(selectedOption.value, "", "", "", 1);
  };
  const handleChangeCategory = (selectedOption) => {
    setSelectedCat(selectedOption);
    const cat = selectedOption.map(function (item) {
      return item.value;
    });
    getStarsList("", cat.toString(), "", "", 1);
  };
  const handleChangePrice = (selectedOption) => {
    setSelectedPrice(selectedOption);
    getStarsList("", "", selectedOption.value, "", 1);
  };
  // const handleSearch = (e) => {
  //   setSearchString(e.target.value)
  //   getStarsList('', '', '', e.target.value, 1)
  // }
  const handleSearch = async (e) => {
    const formData = new FormData();
    setSearchValue(e.target.value)
    formData.append("search", e.target.value);
    formData.append("page_no", 1);
    localStorage.setItem("search" , e.target.value)
    const resp = await fetchStarts(formData);
    if (resp.data.status) {
      const data = jwtDecode(resp.data.data);
      // setStarsList(data.agents);
      setStars(data.agents);
    }
    // const query = e.target.value.toLowerCase();
    // setSearchString(query);  
    // const filterStars = starsList.filter(star => star.first_name.toLowerCase().includes(query) || star.last_name.toLowerCase().includes(query));
    // setStars(filterStars);
  };

  useEffect(()=>{
    const getSearch = async()=>{
      if(searchValue){
        const formData = new FormData();
        formData.append("search", searchValue);
        formData.append("page_no", 1);
        const resp = await fetchStarts(formData);
        if (resp.data.status) {
          const data = jwtDecode(resp.data.data);
          setStarsList(data.agents);
          setStars(data.agents);
        }
      }
    }
    getSearch()
  },[])

  const [show, setShow] = useState(false);
  const [modalSection, setModalSection] = useState(null);

  const handleClose = () => setShow(false);

  const handleShow = (section) => {
    setShow(true);
    setModalSection(section);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getStarsList("", "", "", "", pageNumber);
  };

  const [totalfilter, setTotslFilter] = useState(0);

  const handleFilter = () => {
    const formData = new FormData();
    let filterData = { ...filterHistory };
    let count = 0;

    if (mathooqs?.value) {
      formData.append("mawthooq_status", mathooqs.value);
      filterData = { ...filterData, mawthooq_status: mathooqs };
      count++;
    }

    if (ages.length > 0 && Array.isArray(ages)) {
      const agedata = ages
        .map((age) => {
          switch (age.value) {
            case "13 to 17":
              return { age_range_start: 13, age_range_end: 17 };
            case "18 to 24":
              return { age_range_start: 18, age_range_end: 24 };
            case "25 to 34":
              return { age_range_start: 25, age_range_end: 34 };
            case "35 to 44":
              return { age_range_start: 35, age_range_end: 44 };
            case "Custom":
              return { age_range_start: MinAge, age_range_end: MaxAge };
            default:
              return null;
          }
        })
        .filter((item) => item !== null);
      formData.append("age_range", JSON.stringify(agedata));
      count++;
    }

    if (categoryvalue.length > 0) {
      filterData = { ...filterData, categoryvalue: categoryvalue };
      const catArr = categoryvalue.map(function (item) {
        return item["value"];
      });

      formData.append("skill_id", catArr);
      count++;
    }

    if (sort.value) {
      filterData = { ...filterData, sort_by: sort };
      formData.append("sort_by", sort.value);
      count++;
    }

    if (genders.value) {
      formData.append("gender", genders.value);
      count++;
    }

    if (selectedPrices.length > 0) {
      filterData = { ...filterData, price_range: selectedPrices };
      const pricedata = selectedPrices
        .map((priceRange) => {
          switch (priceRange.value.trim()) {
            case "$0 to $1,000":
              return { price_range_start: 0, price_range_end: 1000 };
            case "$1,000 to $2,500":
              return { price_range_start: 1000, price_range_end: 2500 };
            case "$2,500 to $5,000":
              return { price_range_start: 2500, price_range_end: 5000 };
            case "$5,000 to $10,000":
              return { price_range_start: 5000, price_range_end: 10000 };
            case "$10,000+":
              return { price_range_start: 10000, price_range_end: Infinity };
            case "Custom":
              return {
                price_range_start: customPriceMin,
                price_range_end: customPriceMax,
              };
            default:
              console.error("Unrecognized price range:", priceRange);
              return null;
          }
        })
        .filter((item) => item !== null);
      if (pricedata.length === 0) {
        console.error(
          "No matching price ranges found in selectedPrices:",
          selectedPrices
        );
      }
      formData.append("price_range", JSON.stringify(pricedata));
      count++;
    }

    if (selectedCity.id) {
      formData.append("cityID", selectedCity.id);
      count++;
    }

    if (selectedCountry.id) {
      formData.append("countryID", selectedCountry.id);
      count++;
    }

    if (selectedFollowers.length > 0) {
      const followerRanges = selectedFollowers
        .map((followerRange) => {
          switch (followerRange.value) {
            case "0 to 100000":
              return { followers_range_start: 0, followers_range_end: 100000 };
            case "100000 to 500000":
              return {
                followers_range_start: 100000,
                followers_range_end: 500000,
              };
            case "500000 to 1000000":
              return {
                followers_range_start: 500000,
                followers_range_end: 1000000,
              };
            case "1000000 to 5000000":
              return {
                followers_range_start: 1000000,
                followers_range_end: 5000000,
              };
            case "5000000+":
              return { followers_range_start: 5000000, followers_range_end: 0 };
            case "Custom":
              return {
                followers_range_start: customMin,
                followers_range_end: customMax,
              };
            default:
              break;
          }
        })
        .filter((item) => item !== null);
      formData.append("followers_range", JSON.stringify(followerRanges));
      count++;
    }
    formData.append("limit", itemsPerPage);
    formData.append("page_no", currentPage);
    setTotslFilter(count);
    localStorage.setItem("filterData", JSON.stringify(filterData));
    setFilterHistory(filterData);

    // for (var pair of formData.entries()) {
    //   console.log('formdata',pair[0] + ': ' + pair[1]);
    // }

    setShow(false);
    return formData;
  };

  // const [count,setCount] = useState(0);
  // console.log('count',count)

  const getDataNofilter = async()=>{
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("limit", 8);
      formData.append("page_no", currentPage);
      const resp = await fetchStarts(formData);

      if (resp.data.status) {
        const data = jwtDecode(resp.data.data);
        setStarsList(data.agents);
        setImagePath(data.path);
        setStarsCount(data.user_count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching stars", error);
      // Handle the error as needed
    }

  }

  const handleCloseFilter = () => {
    setSelectedPrices([]);
    setSelectedFollowers([]);
    setSelectedCountry("");
    setGenders("");
    setSort("");
    setcategoryValue([]);
    setAges([]);
    setmathooqs("");
    setSelectedCity("");
    setTotslFilter("");
    setSelectedPrice([])
    localStorage.removeItem("filterData");
    setFilterHistory()
    getDataNofilter()
  };

  const prices = [
    { value: "$0 to $1,000 ", label: "$0 - $1,000" },
    { value: "$1,000 to $2,500", label: "$1,000 - $2,500" },
    { value: "$2,500 to $5,000", label: "$2,500 - $5,000" },
    { value: "$5,000 to $10,000", label: "$5,000 - $10,000" },
    { value: "$10,000+", label: "$10,000" },
    { value: "Custom", label: "Custom" },
  ];


  const [customPriceMin, setCustomPriceMin] = useState(0);
  const [customPriceMax, setCustomPriceMax] = useState(0);

  const handleMinCustomPriceInputChange = (event) => {
    setCustomPriceMin(event.target.value);
  };

  const handleMaxCustomPriceInputChange = (event) => {
    setCustomPriceMax(event.target.value);
  };

  const handlePriceChange = (e, price) => {
    setSelectedPrices([price]);
    // const { checked } = e.target;
    // if (checked) {
    //   setSelectedPrices([...selectedPrices, price]);
    // } else {
    //   setSelectedPrices(selectedPrices.filter(item => item.value !== price.value));
    // }
  };

  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [customMin, setCustomMin] = useState(0);
  const [customMax, setCustomMax] = useState(0);

  const Followers = [
    { value: "0 to 100000", label: "0K - 100K" },
    { value: "100000 to 500000", label: "100K - 500K" },
    { value: "500000 to 1000000", label: "500K - 1M" },
    { value: "1000000 to 5000000", label: "1M - 5M" },
    { value: "5000000+", label: "5M+" },
    { value: "Custom", label: "Custom" },
  ];

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const selectedFollwersdata = Followers.find(
      (option) => option.value === value
    );
    if (checked) {
      setSelectedFollowers([...selectedFollowers, selectedFollwersdata]);
    } else {
      setSelectedFollowers(
        selectedFollowers.filter((item) => item.value !== value)
      );
    }
  };

  const handleMinCustomInputChange = (event) => {
    setCustomMin(event.target.value);
  };

  const handleMaxCustomInputChange = (event) => {
    setCustomMax(event.target.value);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [filteredCountry, setFilteredCountry] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    if (selectedCountry.id) {
      getCity(selectedCountry.id);
    }
  }, [selectedCountry]);

  const getCountry = async () => {
    try {
      const response = await fetchCountry();
      if (response.data.status) {
        const data = jwtDecode(response.data.data);
        setCountry(data.data);
        setFilteredCountry(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchs = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = country.filter(
      (country) =>
        country.countryName && country.countryName.toLowerCase().includes(query)
    );
    setFilteredCountry(filtered);
  };

  const getCity = async (countryId) => {
    try {
      const response = await fetchCity(countryId);

      if (response.data.status) {
        const data = jwtDecode(response.data.data);

        setCity(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountryChange = (item) => {
    setSelectedCountry(item);
  };

  const handleCityChange = (item) => {
    setSelectedCity(item);
  };

  const Gender = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  const [genders, setGenders] = useState("");
  const handleGenderChange = (event) => {
    const selectedGender = Gender.find(
      (option) => option.value === event.target.value
    );
    setGenders(selectedGender);
  };

  const sortOptions = [
    { value: "PRICEHIGHTOLOW", label: "Price: high to low" },
    { value: "PRICELOWTOHIGH", label: "Price: low to high" },
    { value: "FOLLOWERHIGHTOLOW", label: "Number of follower: high to low" },
    { value: "FOLLOWERLOWTOHIGH", label: "Number of follower: low to high" },
    { value: "NEWEST", label: "Newest" },
  ];


  const handleSortchange = (event) => {
    const selectedSort = sortOptions.find(
      (option) => option.value === event.target.value
    );
    setSort(selectedSort);
  };


  const [filteredCategory, setFilteredCategory] = useState([]);
  const [searchcatQuery, setSearchCatQuery] = useState("");

  const handleCategoryChange = (e, category) => {
    const { checked } = e.target;

    if (checked) {
      setcategoryValue((prevCategoryValue) => [...prevCategoryValue, category]);
    } else {
      setcategoryValue((prevCategoryValue) =>
        prevCategoryValue.filter((item) => item.value !== category.value)
      );
    }
  };

  const handleCategorySearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchCatQuery(query);

    const filtered = categoryList.filter(
      (cat) => cat && cat.label && cat.label.toLowerCase().includes(query)
    );

    setFilteredCategory(filtered);
  };

  const age = [
    { value: "13 to 17", label: "13 - 17" },
    { value: "18 to 24", label: "18 - 24" },
    { value: "25 to 34", label: "25 - 34" },
    { value: "35 to 44", label: "35 - 44" },
    { value: "Custom", label: "Custom" },
  ];

  const [ages, setAges] = useState([]);
  const [MinAge, setMinAge] = useState(0);
  const [MaxAge, setMaxAge] = useState(0);

  const handleAgeChange = (e) => {
    const { value, checked } = e.target;
    const selectedSort = age.find((option) => option.value === value);
    if (checked) {
      setAges([...ages, selectedSort]);
    } else {
      setAges(ages.filter((item) => item.value !== value));
    }
  };

  const handleAgeMinCustomInputChange = (event) => {
    setMinAge(event.target.value);
  };

  const handleAgeMaxCustomInputChange = (event) => {
    setMaxAge(event.target.value);
  };

  const mawthooq = [
    { value: "0", label: "Any" },
    { value: "2", label: "Yes, Have mawthooq number" },
    { value: "1", label: "No, Have not" },
  ];


  const handlemathooq = (event) => {
    const selectedmathooq = mawthooq.find(
      (option) => option.value === event.target.value
    );
    setmathooqs(selectedmathooq);
  };

  return (
    <>
      <section className="findbestsec inflancerlist">
        <div className="container">
          <div className="selectfilter">
            <div className="selectfiltermain">
              <Button
                variant="primary"
                className={sort ? "selectedfilter" : "primary"}
                onClick={() => handleShow("sort")}
                value={selectedSortby}
                onChange={handleChangeSort}
              >
                {sort ? (
                  sort?.label
                ) : (
                  <>
                    Sort by <Image src="/Images/arrowbottom.svg" />
                  </>
                )}
              </Button>

              <Button
                variant="primary"
                className={
                  categoryvalue.length > 0 ? "selectedfilter" : "primary"
                }
                onClick={() => handleShow("category")}
                value={selectedSortby}
                onChange={handleChangeSort}
              >
                {categoryvalue.length > 0 ? (
                  <>{categoryvalue.length}</>
                ) : (
                  <>
                    All Category <Image src="/Images/arrowbottom.svg" />
                  </>
                )}
              </Button>

              <Button
                variant="primary"
                className={
                  Array.isArray(selectedPrices) && selectedPrices.length > 0
                    ? "selectedfilter"
                    : "primary"
                }
                onClick={() => handleShow("price")}
                value={selectedSortby}
                onChange={handleChangeSort}
              >
                {Array.isArray(selectedPrices) && selectedPrices.length > 0 ? (
                  selectedPrices.map((item) => item.label)
                ) : (
                  <>
                    Price <Image src="/Images/arrowbottom.svg" />
                  </>
                )}
              </Button>

              <Button
                variant="primary"
                className={mathooqs ? "selectedfilter" : "primary"}
                onClick={() => handleShow("mawthooq")}
                value={selectedSortby}
                onChange={handleChangeSort}
              >
                {mathooqs?.value === "0" ? (
                  "Any"
                ) : (
                    <>
                      {" "}
                      Mawthooq <Image src="/Images/arrowbottom.svg" />
                    </>
                  ) && mathooqs?.value === "1" ? (
                  "No"
                ) : (
                    <>
                      {" "}
                      Mawthooq <Image src="/Images/arrowbottom.svg" />
                    </>
                  ) && mathooqs?.value === "2" ? (
                  "Yes"
                ) : (
                  <>
                    {" "}
                    Mawthooq <Image src="/Images/arrowbottom.svg" />
                  </>
                )}
              </Button>

              <Button
                variant="primary"
                className={totalfilter ? "selectedfilter" : "primary"}
                onClick={() => handleShow("filters")}
                value={selectedSortby}
                onChange={handleChangeSort}
              >
                <span className="count-filter">
                  {totalfilter ? <span>{totalfilter}</span> : 0}
                </span>{" "}
                <Image src="/Images/filter.svg" />
                Filters
              </Button>

              <Button
                variant=""
                className="cancelbtn-outside"
                onClick={handleCloseFilter}
                placeholder={<div></div>}
              >
                Clear
              </Button>
            </div>

          <div className="search-header">
            <Form.Control
              type="search"
              placeholder="Search "
              className="me-2 searchmain"
              aria-label="Search"
              onChange={handleSearch}
              value={searchValue}
            />
            <Icon icon="iconamoon:search-light" />
          </div>
        </div>
        {/* Pagination Controls */}

        {/* Influencer Profiles */}
        {!isLoading ? (
          <Row>
          {Star && Star.length > 0 ? 
              Star.map((item, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <InfluencerProfile data={item} imagePath={imagePath} />
                </Col>
              ))         
            : 
              starsList.map((item, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <InfluencerProfile data={item} imagePath={imagePath} />
                </Col>
              ))
          }
           
          </Row>
        ) : (
          <div className="spinner-box">
            <div className="circle-border">
              <div className="circle-core"></div>
            </div>
          </div>
        )}
        {
          !isLoading  &&

        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <Icon icon="mynaui:arrow-left" width="16px" height="16px" />
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <Icon icon="mynaui:arrow-right" width="16px" height="16px" />
          </button>
        </div>
        }
      </div>
    </section>



          <Modal show={show} onHide={handleClose} className={`pricemodal filtermdal ${modalSection === 'filters' ? 'pricemodal filtermdal filtermain' : ''}`}  aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
          {modalSection === 'sort' && (
              // Render Sort By section
              <div className="price">
              <h4>Sort By</h4>
              {sort ? sort.label : ""}
              <ul className="pricemain pricecss">
                {sortOptions.map(({ value, label }) => (
                  <li key={value}>
                    <label htmlFor={value}>{label}</label>

                    <input
                      type="radio"
                      id={value}
                      name="Sort"
                      value={value}
                      onChange={handleSortchange}
                      checked={value === sort.value}
                    />
                  </li>
                ))}
              </ul>
              <button className="btnblue viewresult" onClick={getStarsList}>
                View results
              </button>
              <button
                className="btnclear viewresult"
                onClick={handleCloseFilter}
              >
                Clear
              </button>
            </div>
          )}

          {modalSection === "category" && (
            // Render Category section
            <div className="price checkcategories">
              <h4>Category</h4>

              <div className="search-header">
                <Form.Control
                  type="search"
                  placeholder="Search "
                  className="me-2 "
                  aria-label="Search"
                  value={searchcatQuery}
                  onChange={(e) => handleCategorySearch(e)}
                />
                <Icon icon="iconamoon:search-light" />
              </div>
              {Array.isArray(categoryvalue) &&
                categoryvalue.map((item, index) => (
                  <React.Fragment key={item.value}>
                    {item.label}
                    {index < categoryvalue.length - 1 && ", "}
                  </React.Fragment>
                ))}
              {!searchcatQuery ? (
                <ul className="pricemain">
                  {categoryList.map((item, index) => {
                    return (
                      <li key={index}>
                        <label htmlFor={`cat-${index}`}>{item?.label}</label>
                        <input
                          type="checkbox"
                          id={`cat-${index}`}
                          name="category"
                          value={item?.value}
                          onChange={(e) => handleCategoryChange(e, item)}
                          checked={categoryvalue
                            .map((item) => item.label)
                            .includes(item?.label)}
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="pricemain">
                  {filteredCategory.map((item, index) => {
                    return (
                      <li key={index}>
                        <label htmlFor={`cat-${index}`}>{item?.label}</label>
                        <input
                          type="checkbox"
                          id={`cat-${index}`}
                          name="category"
                          value={item?.value}
                          onChange={(e) => handleCategoryChange(e, item)}
                          checked={categoryvalue
                            .map((item) => item.label)
                            .includes(item?.label)}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
              <button className="btnblue viewresult" onClick={getStarsList}>
                View results
              </button>
              <button
                className="btnclear viewresult"
                onClick={handleCloseFilter}
              >
                Clear
              </button>
            </div>
          )}

          {modalSection === "price" && (
            // Render Price section
            <div className="price">
              <h4>Price</h4>
              {Array.isArray(selectedPrices) &&
                selectedPrices.map((item) => item.label)}
              <ul className="pricemain pricecss">
                {prices.map((item, index) => (
                  <li key={index}>
                    <label htmlFor={`price-${index}`}>{item?.label}</label>
                    <input
                      type="radio"
                      name="price"
                      id={`price-${index}`}
                      value={item?.value}
                      onChange={(e) => handlePriceChange(e, item)}
                      checked={
                        selectedPrices.filter(
                          (item1) => item1.label === item?.label
                        ).length > 0
                      }
                      // checked={selectedPrices.filter(item => item?.label).includes(item?.label)}
                    />
                  </li>
                ))}
                {Array.isArray(selectedPrices) &&
                  selectedPrices.filter((item) => item.label === "Custom")
                    .length > 0 && (
                    <li className="d-block">
                      <label>Custom</label>
                      <div className="minmax">
                        <input
                          type="number"
                          placeholder="Min"
                          value={customPriceMin}
                          onChange={handleMinCustomPriceInputChange}
                        />
                        <span>-</span>
                        <input
                          type="number"
                          placeholder="Max"
                          value={customPriceMax}
                          onChange={handleMaxCustomPriceInputChange}
                        />
                      </div>
                    </li>
                  )}
              </ul>
              <button className="btnblue viewresult" onClick={getStarsList}>
                View results
              </button>
              <button
                className="btnclear viewresult"
                onClick={handleCloseFilter}
              >
                Clear
              </button>
            </div>
          )}

          {modalSection === "mawthooq" && (
            // Render Mawthooq section
            <div className="price">
              <h4>Mawthooq number</h4>
              {mathooqs && `${mathooqs.label}`}
              <ul className="pricemain pricecss">
                {mawthooq.map(({ value, label }) => (
                  <li key={value}>
                    <label htmlFor={`math-${value}`}>{label}</label>
                    <input
                      type="radio"
                      name="mawthooq"
                      id={`math-${value}`}
                      value={value}
                      onChange={handlemathooq}
                      checked={label === mathooqs?.label}
                    />
                  </li>
                ))}
              </ul>
              <button className="btnblue viewresult" onClick={getStarsList}>
                View results
              </button>
              <button
                className="btnclear viewresult"
                onClick={handleCloseFilter}
              >
                Clear
              </button>
            </div>
          )}

          {modalSection === "filters" && (
            // Render Filters section
            <div className="price mb-0">
              <h4>Filters</h4>
              {/* <Accordion defaultActiveKey="0" className="filteraccord"> */}
              <Accordion className="filteraccord">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {" "}
                    Sort By{" "}
                    <ContextAwareToggle eventKey="0"></ContextAwareToggle>
                  </Accordion.Header>
                  {sort ? sort.label : ""}
                  <Accordion.Body>
                    <ul className="pricemain pricecss">
                      {sortOptions.map(({ value, label }) => (
                        <li key={value}>
                          <label htmlFor={`sort-${value}`}>{label}</label>
                          <input
                            type="radio"
                            id={`sort-${value}`}
                            name="Sort"
                            value={value}
                            onChange={handleSortchange}
                            checked={value === sort.value}
                          />
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Category{" "}
                    <ContextAwareToggle eventKey="1"></ContextAwareToggle>{" "}
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="search-header">
                      <Form.Control
                        type="search"
                        placeholder="Search "
                        className="me-2"
                        aria-label="Search"
                        value={searchcatQuery}
                        onChange={(e) => handleCategorySearch(e)}
                      />
                      <Icon icon="iconamoon:search-light" />
                    </div>
                    {Array.isArray(categoryvalue) &&
                      categoryvalue.map((item, index) => (
                        <React.Fragment key={item.value}>
                          {item.label}
                          {index < categoryvalue.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    {!searchcatQuery ? (
                      <ul className="pricemain">
                        {categoryList.map((item, index) => {
                          return (
                            <li key={index}>
                              <label htmlFor={`cat-${index}`}>
                                {item?.label}
                              </label>
                              <input
                                type="checkbox"
                                id={`cat-${index}`}
                                name="category"
                                value={item?.value}
                                onChange={(e) => handleCategoryChange(e, item)}
                                checked={categoryvalue
                                  .map((item) => item.label)
                                  .includes(item?.label)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <ul className="pricemain">
                        {filteredCategory.map((item, index) => {
                          return (
                            <li key={index}>
                              <label htmlFor={`cat-${index}`}>
                                {item?.label}
                              </label>
                              <input
                                type="checkbox"
                                id={`cat-${index}`}
                                name="category"
                                value={item?.value}
                                onChange={(e) => handleCategoryChange(e, item)}
                                checked={categoryvalue
                                  .map((item) => item.label)
                                  .includes(item?.label)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {" "}
                    Price <ContextAwareToggle eventKey="2"></ContextAwareToggle>
                  </Accordion.Header>
                  {Array.isArray(selectedPrices) &&
                    selectedPrices.map((item) => item.label)}
                  <Accordion.Body>
                    <ul className="pricemain pricecss">
                      {prices.map((item, index) => (
                        <li key={index}>
                          <label htmlFor={`price-${index}`}>
                            {item?.label}
                          </label>
                          <input
                            type="radio"
                            name="price"
                            id={`price-${index}`}
                            value={item?.value}
                            onChange={(e) => handlePriceChange(e, item)}
                            checked={
                              selectedPrices.filter(
                                (item1) => item1.label === item?.label
                              ).length > 0
                            }
                            // checked={selectedPrices.filter(item => item?.label).includes(item?.label)}
                          />
                        </li>
                      ))}

                      {Array.isArray(selectedPrices) &&
                        selectedPrices.filter((item) => item.label === "Custom")
                          .length > 0 && (
                          <li className="d-block">
                            <label>Custom</label>
                            <div className="minmax">
                              <input
                                type="number"
                                placeholder="Min"
                                value={customPriceMin}
                                onChange={handleMinCustomPriceInputChange}
                              />
                              <span>-</span>
                              <input
                                type="number"
                                placeholder="Max"
                                value={customPriceMax}
                                onChange={handleMaxCustomPriceInputChange}
                              />
                            </div>
                          </li>
                        )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Mawthooq number{" "}
                    <ContextAwareToggle eventKey="3"></ContextAwareToggle>
                  </Accordion.Header>
                  {mathooqs && `${mathooqs.label}`}
                  <Accordion.Body>
                    <ul className="pricemain pricecss">
                      {mawthooq.map(({ value, label }) => (
                        <li key={value}>
                          <label htmlFor={`math-${value}`}>{label}</label>
                          <input
                            type="radio"
                            name="mawthooq"
                            id={`math-${value}`}
                            value={value}
                            onChange={handlemathooq}
                            checked={label === mathooqs?.label}
                          />
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {" "}
                    Number of followers
                    <ContextAwareToggle eventKey="4"></ContextAwareToggle>
                  </Accordion.Header>
                  {Array.isArray(selectedFollowers) &&
                    selectedFollowers.map((item, index) => (
                      <React.Fragment key={item.value}>
                        {item.label}
                        {index < categoryvalue.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  <Accordion.Body>
                    <ul className="pricemain pricecss">
                      {Followers.map(({ value, label }) => (
                        <li key={value}>
                          <label htmlFor={`foll-${value}`}>{label}</label>
                          <input
                            type="checkbox"
                            name="followers"
                            id={`foll-${value}`}
                            value={value}
                            onChange={handleCheckboxChange}
                            checked={selectedFollowers
                              .map((item) => item.label)
                              .includes(label)}
                          />
                        </li>
                      ))}
                      {Array.isArray(selectedFollowers) &&
                        selectedFollowers.filter(
                          (item) => item.label === "Custom"
                        ).length > 0 && (
                          <li className="d-block">
                            <label>Custom</label>
                            <div className="minmax">
                              <input
                                type="number"
                                id="customMin"
                                placeholder="Min"
                                value={customMin}
                                onChange={handleMinCustomInputChange}
                              />
                              <span>-</span>
                              <input
                                type="number"
                                id="customMax"
                                placeholder="Max"
                                value={customMax}
                                onChange={handleMaxCustomInputChange}
                              />
                            </div>
                          </li>
                        )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    {" "}
                    Gender<ContextAwareToggle eventKey="5"></ContextAwareToggle>
                  </Accordion.Header>
                  {genders && `${genders.label}`}
                  <Accordion.Body>
                    <ul className="pricemain pricecss">
                      {Gender.map(({ value, label }) => (
                        <li key={value}>
                          <label htmlFor={`gender-${value}`}>{label}</label>
                          <input
                            type="radio"
                            name="gender"
                            id={`gender-${value}`}
                            value={value}
                            onChange={handleGenderChange}
                            checked={label === genders?.label}
                          />
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    {" "}
                    Age<ContextAwareToggle eventKey="6"></ContextAwareToggle>
                  </Accordion.Header>
                  {Array.isArray(ages) &&
                    ages.map((item, index) => (
                      <React.Fragment key={item.value}>
                        {item.label}
                        {index < categoryvalue.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  <Accordion.Body>
                    <ul className="pricemain pricecss">
                      {age.map(({ value, label }) => (
                        <li key={value}>
                          <label htmlFor={`age-${value}`}>{label}</label>
                          <input
                            type="checkbox"
                            name="age"
                            id={`age-${value}`}
                            value={value}
                            onChange={handleAgeChange}
                            checked={ages
                              .map((item) => item.label)
                              .includes(label)}
                          />
                        </li>
                      ))}
                      {Array.isArray(ages) &&
                        ages.filter((item) => item.label === "Custom").length >
                          0 && (
                          <li className="d-block">
                            <label>Custom</label>
                            <div className="minmax">
                              <input
                                type="number"
                                id="customMin"
                                placeholder="Min"
                                value={MinAge}
                                onChange={handleAgeMinCustomInputChange}
                              />
                              <span>-</span>
                              <input
                                type="number"
                                id="customMax"
                                placeholder="Max"
                                value={MaxAge}
                                onChange={handleAgeMaxCustomInputChange}
                              />
                            </div>
                          </li>
                        )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    {" "}
                    Star’s location
                    <ContextAwareToggle eventKey="7"></ContextAwareToggle>
                  </Accordion.Header>
                  {selectedCountry.countryName}
                  {selectedCity.cityName}
                  <Accordion.Body>
                    <div className="search-header">
                      <div className="searchicon">
                        <Form.Control
                          type="search"
                          placeholder="Search "
                          className="me-2 mb-4"
                          aria-label="Search"
                          value={searchQuery}
                          onChange={(e) => handleSearchs(e)}
                        />
                        <Icon icon="iconamoon:search-light" />
                      </div>
                      <div className="selectedcity d-flex">
                        {!searchQuery ? (
                          <div className="selectoption d-flex">
                            <ul className="pricemain starslocation">
                              {country.map((item, index) => (
                                <li key={index}>
                                  <label htmlFor={`c-${index}`}>
                                    {item?.countryName}
                                  </label>
                                  <input
                                    id={`c-${index}`}
                                    type="radio"
                                    name="country"
                                    value={item?.id}
                                    onChange={() => handleCountryChange(item)}
                                    checked={
                                      selectedCountry.countryName ===
                                      item?.countryName
                                    }
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <ul>
                            {filteredCountry.map((country) => (
                              <li key={country.id}>
                                <label htmlFor={country.id}>
                                  {country.countryName}
                                </label>
                                <input
                                  type="radio"
                                  name="country"
                                  id={country.id}
                                  value={country.countryName}
                                  onChange={() => handleCountryChange(country)}
                                  checked={
                                    selectedCountry.countryName ===
                                    country?.countryName
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                        <ul className="pricemain starslocation">
                          {city.map((item, index) => (
                            <li key={index}>
                              <label htmlFor={index}>{item.cityName}</label>
                              <input
                                type="radio"
                                name="city"
                                id={index}
                                value={item.id}
                                onChange={() => handleCityChange(item)}
                                checked={
                                  selectedCity.cityName === item?.cityName
                                }
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <button className="btnblue viewresult" onClick={getStarsList}>
                View results
              </button>
              <button
                className="btnclear viewresult"
                onClick={handleCloseFilter}
              >
                Clear
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Inflancers;
