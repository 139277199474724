// @ts-nocheck
import "./HomePage.css";
import React, { useEffect } from "react";
import HomeBanner from "../../component/HomePageComponent/HomeBanner/HomeBanner";
import FindtheBest from "../../component/HomePageComponent/FindtheBest/FindtheBest";
import BussinessWork from "../../component/HomePageComponent/BussinessWork/BussinessWork";
import ServingBothSide from "../../component/HomePageComponent/ServingBothSide/ServingBothSide";
import FAQ from "../../component/HomePageComponent/FAQ/FAQ";
import Nojom from "../../component/HomePageComponent/Nojom/Nojom";
import DownloadNojom from "../../component/HomePageComponent/DownloadNojom/DownloadNojom";
// import { Helmet } from "react-helmet";
import MetaDecorator from "../../utils/metaDecorator";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
const HomePage = ({ setIsProfile }) => {
  const { t } = useTranslation();
  useEffect(() => {
    setIsProfile(false);
  }, [0]);
  return (
    <>
      <MetaDecorator
        title="nojom app | all arab stars, celebrities, and influence in one app"
        description="nojom app is a platform for stars where brands can find, compare, chat with, and work with Arab influencers and celebrities"
      />
      <HomeBanner></HomeBanner>
      <FindtheBest></FindtheBest>
      <BussinessWork></BussinessWork>
      <ServingBothSide></ServingBothSide>
      <FAQ />
      <Nojom></Nojom>
      <DownloadNojom></DownloadNojom>
    </>
  );
};
export default HomePage;
