import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const language = localStorage.getItem("language");
i18n.use(initReactI18next).init({
  fallbackLng: "ar",
  lng: language,
  resources: {
    en: {
      // @ts-ignore
      translations: require("./locales/en.json")
    },
    ar: {
      // @ts-ignore
      translations: require("./locales/ar.json")
    }
  },
  ns: ["translations"],
  defaultNS: "translations"
});
i18n.languages = ["en", "ar"];
export default i18n;
