// @ts-nocheck
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom/dist";
import { Fragment, useState } from "react";
import React from "react";
import ModalComponent from "../../Modal/Modal-Component";
import styles from "./homebanner.module.css";
import { useTranslation } from "react-i18next";
/**
 * Renders the HomeBanner component.
 *
 * @return {JSX.Element} The rendered HomeBanner component.
 */
const HomeBanner = () => {
  const [showBrand, setShowBrand] = useState(false);
  const [showStar, setShowStar] = useState(false);
  const handleCloseBrand = () => setShowBrand(false);
  const handleShowBrand = () => setShowBrand(true);
  const handleCloseStar = () => setShowStar(false);
  const handleShowStar = () => setShowStar(true);
  return (
    <>
      <section
        className={`${styles.header} sm:!static sm:after:hidden md:after:hidden sm:!min-h-0 sm:pb-12 sm:pt-32`}
      >
        <div className="container">
          <Row className="sm:gap-7 items-center">
            <Col className="flex-1">
              <div className="flex flex-col gap-12 sm:gap-7">
                <ContentContainer />
                <ButtonsContainer
                  handleShowBrand={handleShowBrand}
                  handleShowStar={handleShowStar}
                />
              </div>
            </Col>
            <Col className="flex-1 sm:hidden">
              <HeroImgContainer />
            </Col>
          </Row>
        </div>
      </section>
      <ModalComponent
        isOpen={showBrand}
        openModal={handleShowBrand}
        closeModal={handleCloseBrand}
        title={"Brand"}
      />
      <ModalComponent
        isOpen={showStar}
        openModal={handleShowStar}
        closeModal={handleCloseStar}
        title={"Star"}
      />
    </>
  );
};
export default HomeBanner;
/**
 * Renders a container with an image of the hero and applies CSS classes for positioning and visibility.
 *
 * @return {JSX.Element} The container with the hero image.
 */
const HeroImgContainer = () => {
  return (
    <div
      className={`${styles.heroImg} absolute top-[20%] z-40 sm:hidden md:hidden`}
    >
      <Image src="/Images/heroimg.png" width={698} height={495} />
    </div>
  );
};
/**
 * Renders a container with content for the HomeBanner component.
 *
 * @return {React.Fragment} The container with the content.
 */
const ContentContainer = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const ar = i18n.language === "ar";
  return (
    <Fragment>
      <div>
        {ar ? (
          <h1 className={styles.head1}>
            <span>{t("home.TitleStars")} </span> {t("home.TitleArab")}{" "}
            {t("home.TitleInOneApp")}
          </h1>
        ) : (
          <h1 className={styles.head1}>
            {t("home.TitleArab")} <span>{t("home.TitleStars")}</span>{" "}
            {t("home.TitleInOneApp")}
          </h1>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <h5 className={`${styles.head5} sm:hidden`}>
          {t("home.TitleDiscoverCollaborateAndWork")}
        </h5>
        <p className={`${styles.text} break-normal`}>
          {t("home.TextNojomIsBestAffordableSolution")}
        </p>
      </div>
    </Fragment>
  );
};
/**
 * Renders a container with two buttons for the HomeBanner component.
 *
 * @param {Object} props - The props object.
 * @param {function} props.handleShowBrand - The function to handle showing the brand.
 * @param {function} props.handleShowStar - The function to handle showing the star.
 * @return {JSX.Element} The container with the buttons.
 */
const ButtonsContainer = ({ handleShowBrand, handleShowStar }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center sm:w-full sm:justify-normal gap-6 sm:gap-4">
      <Link
        to=""
        className={`${styles.btn} ${styles.btn1} sm:flex-1 sm:h-[48px] sm:!text-[16px] sm:!font-[600]`}
        variant="primary"
        onClick={handleShowBrand}
      >
        {t("home.TextBrands")}
      </Link>
      <Link
        to=""
        className={`${styles.btn} ${styles.btn2} sm:flex-1 sm:h-[48px] sm:!text-[16px] sm:!font-[600]`}
        variant="primary"
        onClick={handleShowStar}
      >
        {t("home.TextStars")}
      </Link>
    </div>
  );
};
