// @ts-nocheck
import React, { useState, useEffect, Fragment } from "react";
import { getAgentProfileAgency } from "./action";
import { jwtDecode } from "jwt-decode";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import Loader from "component/Loader/Loader";
import SectionTitle from "./SectionTitle";
import Sperarator from "./Seperator";
function UserMainAgency({ id }) {
  const [userAgencies, setUserAgencies] = useState({
    data: [],
    loading: true
  });
  const [isTextShow, setIsTextShow] = useState(false);
  const getUserAgency = async () => {
    setUserAgencies({
      ...userAgencies,
      loading: true
    });
    await getAgentProfileAgency(id)
      .then((res) => {
        const data = jwtDecode(res.data.data);
        console.log(data);
        setUserAgencies({
          data: data,
          loading: false
        });
      })
      .catch(() => {
        setUserAgencies({
          ...userAgencies,
          loading: false
        });
      });
  };
  useEffect(() => {
    getUserAgency();
  }, []);
  return (
    <Fragment>
      {userAgencies?.data?.length === 0 ? null : (
        <Fragment>
          <Sperarator />
          <section className="section">
            <SectionTitle title="Agency" withIcon={false} />
            <div className="agency-row rtl:flex-row sm:!flex-col">
              <div>
                <Image
                  src={`${process.env.PUBLIC_URL}/Images/Ellipse 79.svg`}
                  alt="img"
                />
              </div>
              <div>
                <Row className="gy-3 gx-3">
                  <Col xs="12" md="6">
                    <div className="agency-item rtl:!flex-row">
                      <div className="agency-info">
                        <Image
                          src={`${process.env.PUBLIC_URL}/Images/Buildings.svg`}
                          alt="img"
                        />
                      </div>
                      <div className="agency-details">
                        <p>{userAgencies?.data?.data?.[0].about}</p>
                      </div>
                    </div>
                  </Col>
                  {userAgencies?.data?.data?.[0].phone && (
                    <Col xs="12" md="6">
                      <div className="agency-item">
                        <div className="agency-info">
                          <Image
                            src={`${process.env.PUBLIC_URL}/Images/Phone Rounded.svg`}
                            alt="img"
                          />
                        </div>
                        <div className="agency-details">
                          <p>{userAgencies?.data?.data?.[0].phone}</p>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col xs="12" md="6">
                    <div className="agency-item">
                      <div className="agency-info">
                        <Image
                          src={`${process.env.PUBLIC_URL}/Images/Letter.svg`}
                          alt="img"
                        />
                      </div>
                      <div className="agency-details">
                        <p>{userAgencies?.data?.data?.[0].email}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="6">
                    <div className="agency-item">
                      <div className="agency-info">
                        <Image
                          src={`${process.env.PUBLIC_URL}/Images/Global.svg`}
                          alt="img"
                        />
                      </div>
                      <div className="agency-details">
                        <p>{userAgencies?.data?.data?.[0].website}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="6">
                    <div className="agency-item">
                      <div className="agency-info">
                        <Image
                          src={`${process.env.PUBLIC_URL}/Images/Map Point.svg`}
                          alt="img"
                        />
                      </div>
                      <div className="agency-details">
                        <p>{userAgencies?.data?.data?.[0].address}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <div className="info flex gap-2 flex-wrap items-center">
                  <div>
                    <Image
                      src={process.env.PUBLIC_URL + "/Images/Danger Circle.svg"}
                      width={24}
                      height={24}
                    />
                  </div>
                  <p
                    className={`${
                      isTextShow ? "w-[85%]" : "line-clamp-1 w-[83%] sm:w-[64%]"
                    }`}
                  >
                    Lorem ipsum is placeholder text commonly used text commonly
                    used in the gra lorem ipsum is placeholder text Lorem ipsum
                    dolor sit amet consectetur, adipisicing elit. Aspernatur
                    sapiente omnis, qui quod, unde alias minima laborum, tempore
                    magni aliquam explicabo dicta maiores beatae accusamus!
                    Labore rem alias aut incidunt...{" "}
                    <span
                      className={isTextShow ? "" : "hidden"}
                      onClick={() => setIsTextShow(false)}
                    >
                      show less
                    </span>
                  </p>
                  <span
                    className={isTextShow ? "hidden" : ""}
                    onClick={() => setIsTextShow(true)}
                  >
                    read more
                  </span>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
}
export default UserMainAgency;
/**
 * 
 *    <div className="flex flex-wrap">
        <p className={isTextShow ? "" : "line-clamp-1 w-[85%]"}>
          Lorem ipsum is placeholder text commonly used text commonly used in
          the gra lorem ipsum is placeholder text Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Aspernatur sapiente omnis, qui quod,
          unde alias minima laborum, tempore magni aliquam explicabo dicta
          maiores beatae accusamus! Labore rem alias aut incidunt...{" "}
          <span
            className={isTextShow ? "" : "hidden"}
            onClick={() => setIsTextShow(false)}
          >
            show less
          </span>
        </p>
        <span
          className={isTextShow ? "hidden" : ""}
          onClick={() => setIsTextShow(true)}
        >
          read more
        </span>
      </div>
 */
