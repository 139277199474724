// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import InfluencersList from "./InfluencersList";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { fetchStarts } from "./action";
import styles from "./findTheBest.module.css";
import { useTranslation } from "react-i18next";
import Loader from "component/Loader/Loader";
const FindtheBest = () => {
  const [starsList, setStarsList] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [starsCount, setStarsCount] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [Star, setStars] = useState([]);
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem("search") || ""
  );
  const handleSearch = async (e) => {
    const formData = new FormData();
    setSearchValue(e.target.value);
    formData.append("search", e.target.value);
    formData.append("page_no", 1);
    localStorage.setItem("search", e.target.value);
    const resp = await fetchStarts(formData);
    if (resp.data.status) {
      const data = jwtDecode(resp.data.data);
      setStarsList(data.agents);
      setStars(data.agents);
    }
  };
  const getSearch = async () => {
    if (searchValue) {
      const formData = new FormData();
      formData.append("search", searchValue);
      formData.append("page_no", 1);
      const resp = await fetchStarts(formData);
      if (resp.data.status) {
        const data = jwtDecode(resp.data.data);
        setStarsList(data.agents);
        setStars(data.agents);
      }
    }
  };
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  const getStarsList = async () => {
    try {
      setIsLoading(true);
      const resp = await fetchStarts();
      if (resp.data.status) {
        const data = jwtDecode(resp.data.data);
        setStarsList(data.agents);
        setImagePath(data.path);
        setStarsCount(data.user_count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching stars", error);
      // Handle the error as needed
    }
  };
  useEffect(() => {
    getStarsList();
    getSearch();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { t } = useTranslation();
  return (
    <div className="container pt-20 sm:pt-0">
      <div className="flex flex-col gap-10 sm:gap-6">
        <SectionTitleAndSearchContainer
          title={t("home.TextFindTheBest")}
          handleSearch={handleSearch}
          searchValue={searchValue}
        />
        <ListOfInfluencersContainer
          handleSearch={handleSearch}
          searchValue={searchValue}
          width={width}
          isLoading={isLoading}
          starsList={starsList}
          star={Star}
          imagePath={imagePath}
        />
      </div>
    </div>
  );
};
export default FindtheBest;
const ListOfInfluencersContainer = ({
  handleSearch,
  searchValue,
  starsList,
  Star,
  imagePath,
  width,
  isLoading
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8">
      {!isLoading ? (
        <Row>
          <InfluencersList
            starsList={starsList}
            imagePath={imagePath}
            Star={Star}
            screenWidth={width}
          />
        </Row>
      ) : (
        <Loader />
      )}
      <Link
        to={`/listofinflancers`}
        className={`${styles.getMoreBtn} sm:!w-full mx-auto`}
      >
        {t("TextViewAll")}
      </Link>
    </div>
  );
};
const SectionTitleAndSearchContainer = ({
  title,
  handleSearch,
  searchValue
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.findTheBestHeader} flex sm:flex-col sm:gap-10 justify-between items-center`}
    >
      <div className="w-full">
        <h2>{title}</h2>
      </div>
      <div className={`${styles.searchInput} flex gap-5 sm:!w-full relative`}>
        <Form.Control
          type="search"
          placeholder={t("TextSearch")}
          className="bg-transparent !p-0 h-[36px] ms-7 !text-[#8E8E93]"
          aria-label="Search"
          onChange={handleSearch}
          value={searchValue}
        />
        <Icon
          width={18}
          height={18}
          icon="iconamoon:search-light"
          className="absolute"
        />
      </div>
    </div>
  );
};
