
import { Col, Image, Row } from 'react-bootstrap';
import './Aboutus.css';

import React from "react";


const AR_AboutusHero = () => {
    return (
        <>
            <section className="aboutussection">
                <div className="container">
                    <div className="aboutcontent">
                        <h2>عن نجوم</h2>
                        <p>في nojom، نعتبر أنفسنا أكثر من مجرد منصة؛ بل نحن مهندسون لصلات ذات مغزى بين المؤثرين والعلامات التجارية.</p>
                        <p> هدفنا هو إعادة تعريف منظر التسويق عبر المؤثرين من خلال توفير مساحة سلسة وآمنة ومبتكرة للتعاون.</p>
                        <p> تفضل بالاستمتاع بتجربة فريدة ومحمية مع nojom، حيث نحن هنا لنجعل كل اتصال ذو قيمة.</p>
                    </div>
                    <Row>
                        <Col>
                            <div className="ourmission">
                                <h3>رؤيتنا:</h3>
                                <p>نحن الدافع لتحقيق تحولات إيجابية في مجال التسويق عبر المؤثرين، نعزز مجتمعًا حيث تتلاقى الإبداع، الثقة، والنجاح بروح رائعة. اقرأ وتمتع بتجربة فريدة، حيث نسعى جاهدين للمحافظة على هذا التوازن المثالي.</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="ourmission">
                                <h3>مهمتنا:</h3>
                                <p>نسعى جاهدين لتمكين المؤثرين والعلامات التجارية من خلال توفير تجربة تواصل سلسة، حلاً آمنًا للمدفوعات، ومجموعة متنوعة من المؤثرين الذين يغطون جميع المجالات. نحن نعمل بلا كلل لنقدم لك تجربة استثنائية تجمع بين الكفاءة والأمان، مع الحرص الدائم على الحفاظ على هذا المستوى المتميز.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >

        </>
    );
}

export default AR_AboutusHero;
