// @ts-nocheck
import React, { useState, useTransition, useEffect, Fragment } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import { getPortfolio } from "./action";
import { jwtDecode } from "jwt-decode";
import Loader from "component/Loader/Loader";
import SectionTitle from "./SectionTitle";
import { useTranslation } from "react-i18next";
import Sperarator from "./Seperator";
function UserMainPortfolio({ id }) {
  const { i18n } = useTranslation();
  const [userPortofolio, setUserPortofolio] = useState({
    data: [],
    page_no: 1,
    loading: false
  });
  const getUserPortofolios = async () => {
    setUserPortofolio({ ...userPortofolio, loading: true });
    await getPortfolio(id, userPortofolio.page_no)
      .then((res) => {
        const data = jwtDecode(res.data.data);
        setUserPortofolio({
          ...userPortofolio,
          data: data,
          loading: false
        });
      })
      .catch(() => {
        setUserPortofolio({
          ...userPortofolio,
          loading: false
        });
      });
  };
  useEffect(() => {
    getUserPortofolios();
  }, [userPortofolio.page_no]);
  console.log(userPortofolio);
  const [_, startTransition] = useTransition();
  function splitArrayIntoChunks(arr, chunkSize = 3) {
    const result = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      result.push(arr?.slice(i, i + chunkSize));
    }
    return result;
  }
  const handleNext = () => {
    startTransition(() => {
      setUserPortofolio((prevState) => ({
        ...prevState,
        page_no: prevState.page_no + 1
      }));
    });
  };
  const handlePrev = () => {
    if (userPortofolio?.page_no >= 2) {
      startTransition(() => {
        setUserPortofolio((prevState) => ({
          ...prevState,
          page_no: prevState.page_no - 1
        }));
      });
    }
  };
  const ItemsAmount = userPortofolio?.page_no * 6;
  if (userPortofolio?.data?.length === 0) return null;
  return (
    <Fragment>
      {userPortofolio?.data?.portfolio?.length > 1 ? (
        <Fragment>
          <Sperarator />
          <section className="section">
            <SectionTitle
              title="Portfolio"
              handleNext={handleNext}
              handlePrev={handlePrev}
              isNextDisabled={userPortofolio?.data?.count <= ItemsAmount}
              isPrevDisabled={userPortofolio?.page_no <= 1}
            />
            <div className="profile-portfolio">
              {splitArrayIntoChunks(userPortofolio?.data?.portfolio)?.map(
                (item, index) => (
                  <div className="portfolio" key={index}>
                    {item?.map((portfolio, index) => (
                      <div class="portfolio-item" key={index}>
                        <div className="portfolio-img">
                          <Image
                            src={`${userPortofolio?.data.path}${portfolio?.filename}`}
                            alt="img-item"
                          />
                          <div className="overlay"></div>
                          {index === 0 && (
                            <div className="company-title-container company-title-left-container rtl:!right-[16px]">
                              <div className="title cursor-text">
                                <Image
                                  src={`${userPortofolio.data.company_path}${portfolio?.company_filename}`}
                                  alt="tabby-icon"
                                  className="rounded"
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "0px"
                                  }}
                                />
                                {i18n.language === "ar"
                                  ? portfolio?.company_name_ar
                                  : portfolio?.company_name}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </section>
        </Fragment>
      ) : (
        <Fragment>
          <Sperarator />
          <section className="section">
            <SectionTitle
              title="Portfolio"
              handleNext={handleNext}
              handlePrev={handlePrev}
              isNextDisabled={userPortofolio?.data?.count <= ItemsAmount}
              isPrevDisabled={userPortofolio?.page_no <= 1}
            />
            <div
              className={`${
                userPortofolio?.data?.portfolio?.length > 1
                  ? "profile-portfolio"
                  : ""
              }`}
            >
              {userPortofolio?.data?.length?.portfolio?.map((item, index) => (
                <div
                  className={`${
                    userPortofolio?.data?.length?.portfolio > 1
                      ? "portfolio"
                      : "portfolio !block w-full"
                  }`}
                  key={index}
                >
                  <div class="portfolio-item" key={index}>
                    <div
                      className={`${
                        userPortofolio?.data?.length?.portfolio > 1
                          ? "portfolio-img"
                          : "portfolio-img !max-w-full !w-full"
                      }`}
                    >
                      <Image
                        src={`${userPortofolio?.data.path}${item?.filename}`}
                        alt="img-item"
                      />
                      <div className="overlay"></div>
                      {index === 0 && (
                        <div className="company-title-container company-title-left-container rtl:!right-[16px]">
                          <div className="title cursor-text">
                            <Image
                              src={`${userPortofolio.data.company_path}${item?.company_filename}`}
                              alt="tabby-icon"
                              className="rounded"
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "0px"
                              }}
                            />
                            {i18n.language === "ar"
                              ? item?.company_name_ar
                              : item?.company_name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
}
export default UserMainPortfolio;
