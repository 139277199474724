// @ts-nocheck
import { Col, Image, Row } from "react-bootstrap";
import "./Footer.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Icon } from "@iconify/react/dist/iconify";
import { Link, useNavigate } from "react-router-dom/dist";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalDetail from "../Header/ModalDetail";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.css";
import ModalComponent from "component/Modal/Modal-Component";
const Footer = ({ isProfile, setLang, lang }) => {
  // const [show, setShow] = useState(false);
  const navigate = useNavigate();
  // let language = localStorage.getItem("lang");
  const [showBrand, setShowBrand] = useState(false);
  const [showStar, setShowStar] = useState(false);
  const handleCloseBrand = () => setShowBrand(false);
  const handleShowBrand = () => setShowBrand(true);
  const handleCloseStar = () => setShowStar(false);
  const handleShowStar = () => setShowStar(true);
  const handleJoin = (e) => {
    e.preventDefault();
  };
  const { i18n } = useTranslation();
  return (
    !isProfile && (
      <>
        <footer>
          <div className="flex flex-col gap-8">
            <FooterMainContentContainer handleJoin={handleJoin} />
            <FooterSubMainContent handleShowBrand={handleShowBrand} />
            <div className="sm:block hidden">
              <FooterSmActionContent handleShowStar={handleShowStar} />
            </div>
            <div className="sm:block hidden">
              <FooterCopyRight />
            </div>
          </div>
        </footer>
        <ModalComponent
          isOpen={showBrand}
          openModal={handleShowBrand}
          closeModal={handleCloseBrand}
          title="Brand"
        />
        <ModalComponent
          isOpen={showStar}
          openModal={handleShowStar}
          closeModal={handleCloseStar}
          title="Star"
        />
      </>
    )
  );
};
export default Footer;
const FooterMainContentContainer = ({ handleJoin }) => {
  return (
    <div className="flex flex-row-reverse  sm:!flex-col-reverse justify-between">
      <div className="sm:hidden">
        <FooterActionContent />
      </div>
      <FooterMail handleJoin={handleJoin} />
    </div>
  );
};
const FooterSubMainContent = ({ handleShowBrand }) => {
  return (
    <div className="flex flex-col  gap-7">
      <FooterNavigate handleShowBrand={handleShowBrand} />
      <div className="sm:hidden">
        <FooterCopyRight />
      </div>
    </div>
  );
};
const FooterMail = ({ handleJoin }) => {
  const { t } = useTranslation();
  return (
    <div className={`flex flex-col gap-5 sm:!gap-4 ${styles.footermail}`}>
      <div>
        <h4>{t("TextJoinOurMailing")}</h4>
      </div>
      <div>
        <p className={styles.mainText}>
          {t("TextBeFirstKnowAboutTheNewestStars")}
        </p>
      </div>
      <div className={styles.mail}>
        <Form onClick={handleJoin}>
          <input type="mail" placeholder="Email address" />
          <button type="submit" className={styles.btn}>
            {t("TextJoin")}
          </button>
        </Form>
      </div>
      <div>
        <p className={styles.subText}>
          {t("TextEmailsSubjectTo")}{" "}
          <Link to="/en/privacypolicy" className={styles.link}>
            {t("TextPrivacyPolicy")}
          </Link>
        </p>
      </div>
    </div>
  );
};
const FooterActionContent = ({ handleShowStar }) => {
  const language = localStorage.getItem("language");
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const handleTogglelanguage = () => {
    const root = document.getElementById("root-html");
    if (language === "ar") {
      root.setAttribute("dir", "ltr");
      localStorage.setItem("dir", "ltr");
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    } else {
      root.setAttribute("dir", "rtl");
      localStorage.setItem("dir", "rtl");
      localStorage.setItem("language", "ar");
      i18n.changeLanguage("ar");
    }
  };
  return (
    <div className={`flex flex-col items-end gap-12 ${styles.actionContent}`}>
      <div>
        <button className={styles.actionBtn} onClick={handleTogglelanguage}>
          <Image src="/Images/english.svg" />
          {language === "en" ? "AR" : "EN"}
        </button>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-end gap-3">
          <div>
            <p className={styles.actionText}>{t("TextReadyToBecomeAStar")}</p>
          </div>
          <div>
            <button
              className={`${styles.actionBtn} !px-7`}
              onClick={handleShowStar}
            >
              {t("TextJoinAsAStar")}
            </button>
          </div>
        </div>
        <div className="flex flex-col items-end gap-3">
          <div>
            <p className={styles.actionText}>{t("TextAreYouAgentManager")}</p>
          </div>
          <div>
            <button className={`${styles.actionBtn} !px-7`}>
              {t("TextRegisterAsAPartner")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const FooterSmActionContent = ({ handleShowStar }) => {
  const language = localStorage.getItem("language");
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const handleTogglelanguage = () => {
    const root = document.getElementById("root-html");
    if (language === "ar") {
      root.setAttribute("dir", "ltr");
      localStorage.setItem("dir", "ltr");
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    } else {
      root.setAttribute("dir", "rtl");
      localStorage.setItem("dir", "rtl");
      localStorage.setItem("language", "ar");
      i18n.changeLanguage("ar");
    }
  };
  return (
    <div className={`flex flex-col gap-12 ${styles.actionContent}`}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <div>
            <p className={styles.actionText}>{t("TextReadyToBecomeATalent")}</p>
          </div>
          <div>
            <button
              className={`${styles.actionBtn} !px-7`}
              onClick={handleShowStar}
            >
              {t("TextJoinAsATalent")}
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <p className={styles.actionText}>{t("TextAreYouAgentManager")}</p>
          </div>
          <div>
            <button className={`${styles.actionBtn} !px-7`}>
              {t("TextRegisterAsAPartner")}
            </button>
          </div>
        </div>
      </div>
      <div>
        <button className={styles.actionBtn} onClick={handleTogglelanguage}>
          <Image src="/Images/english.svg" />
          {language === "en" ? "AR" : "EN"}
        </button>
      </div>
    </div>
  );
};
const FooterNavigate = ({ handleShowBrand }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between sm:flex-col sm:!gap-5">
      <div>
        <ul
          className={`${styles.footerNavigate} flex sm:flex-wrap gap-8 sm:!gap-3`}
        >
          <li>
            <Link to="#">{t("TextFAQs")}</Link>
          </li>
          <li>
            <Link to="/privacypolicy">{t("TextPrivacyPolicy")}</Link>
          </li>
          <li>
            <Link to="/terms">{t("TextTerms")}</Link>
          </li>
          <li>
            <Link to="/aboutus">{t("TextAboutUs")}</Link>
          </li>
          <li>
            <Link to="/contactus">{t("TextContactUs")}</Link>
          </li>
          <li>
            <Link variant="primary" onClick={handleShowBrand}>
              {t("TextDowloadApp")}
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <ul className={`${styles.footerNavigateIcons} flex gap-4 sm:!gap-3`}>
          <li>
            <Link target="_blank" to="http://LinkedIn.com/company/nojomapp">
              <Image src="/Images/footerlinkdin.svg" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="http://Instagram.com/nojomapp">
              <Image src="/Images/footerinstagram.svg" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="https://t.snapchat.com/tEdZl13p">
              <Image src="/Images/footersnapchat.svg" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="http://Twitter.com/nojomapp">
              <Image src="/Images/footertwitter.svg" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="http://www.tikTok.com/@nojomapp">
              <Image src="/Images/footertiktok.svg" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
const FooterCopyRight = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.copyright}>
      <p className="sm:w-full sm:text-center">{t("TextNojomRights")}</p>
    </div>
  );
};
