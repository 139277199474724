
import { Col, Image, Row } from 'react-bootstrap';
import './AR_Terms.css';

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


const AR_Terms = ({ setIsProfile }) => {
    useEffect(() => {
        setIsProfile(false)
    }, [0])
    return (
        <>
            <section className='privacy-policy'>
                <div className="container">
                    <div className="privacy-policy-title">
                        <h2>الشروط والأحكام</h2>
                    </div>
                </div>
                <div className="privacy-policy-info ar">
                    <div className="container">
                        <div className="privacy-bg">
                            <div className="conditions">
                                <p>تم آخر تحديث: يناير 2024<br></br>
                                    مرحبًا بك في نجوم! تحدد هذه الشروط والأحكام قواعد ولوائح استخدام منصتنا.
                                    عند الوصول إلى نجوم أو استخدامها، فإنك توافق على الالتزام بهذه الشروط. يرجى قراءتها بعناية.</p>
                            </div>
                            <div className="conditions">
                                <h3>1. قبول الشروط</h3>
                                <p>من خلال استخدام نجوم، فإنك توافق على الامتثال والتقييد بشروط وأحكام هذا الاتفاق. إذا لم تتفق مع أي جزء من هذه الشروط، يرجى الامتناع عن استخدام المنصة.</p>
                            </div>
                            <div className="conditions">
                                <h3>2. التسجيل والحساب</h3>
                                <p>للوصول إلى بعض ميزات نجوم، قد يُطلب منك إنشاء حساب. أنت مسؤول عن الحفاظ على سرية معلومات حسابك وتوافق على إخطارنا فور حدوث أي استخدام غير مصرح به.</p>
                            </div>
                            <div className="conditions">
                                <h3>3.  محتوى المستخدم</h3>
                                <p>تحتفظ بملكية المحتوى الذي تقدمه إلى نجوم. من خلال نشر المحتوى، تمنح نجوم ترخيصًا غير حصري وقابل للنقل وقابل للترخيص وخالٍ من الرسوم وعلى مستوى العالم لاستخدام وعرض واستنساخ وتوزيع محتواك.</p>
                            </div>
                            <div className="conditions">
                                <h3>4. الأنشطة الممنوعة</h3>
                                <p>توافق على عدم مشاركة في أي أنشطة غير قانونية أو محظورة على نجوم. ويشمل ذلك، على سبيل المثال لا الحصر، انتهاك حقوق الملكية الفكرية، أو نقل الفيروسات، أو المشاركة في أنشطة احتيالية.</p>
                            </div>
                            <div className="conditions">
                                <h3>5. الملكية الفكرية</h3>
                                <p>نجوم ومحتواها الأصلي وميزاتها ووظائفها هي ملكية حصرية لنجوم. قد لا تستنسخ أو توزع أو تعديل أو تخلق أعمالاً مشتقة أو تعرض علنيًا أو تستغل بأي شكل من الأشكال أي محتوى بدون موافقتنا الكتابية المسبقة.</p>
                            </div>
                            <div className="conditions">
                                <h3>6.سياسة الخصوصية</h3>
                                <p>يُحكم استخدامك لنجوم أيضًا بسياسة الخصوصية لدينا. يرجى مراجعة هذه السياسة لفهم كيفية جمعنا واستخدامنا وحمايتنا لمعلوماتك.</p>
                            </div>
                            <div className="conditions">
                                <h3>7. إنهاء الخدمات</h3>
                                <p>نحتفظ بحقنا في إنهاء أو تعليق وصولك إلى نجوم بتقديرنا، دون إشعار، لأي سبب، بما في ذلك انتهاك هذه الشروط والأحكام.</p>
                            </div>
                            <div className="conditions">
                                <h3>8. تغيير الشروط</h3>
                                <p>قد نقوم بتحديث هذه الشروط والأحكام من وقت لآخر. يكون أي تغيير فعّالًا فور نشره. متابعة استخدام نجوم بعد مثل هذه التغييرات يشكل موافقتك على الشروط المعدلة.</p>
                            </div>
                            <div className="conditions">
                                <h3>9.القانون الساري</h3>
                                <p>تُحكم هذه الشروط والأحكام وتُفسر وفقًا لقوانين المملكة العربية السعودية، دون النظر إلى أحكام التعارض في القوانين.</p>
                            </div>

                            <p>الرجاء إرسال أي أسئلة حول سياسة الخصوصية إلى البريد الإلكتروني:<Link>support@nojom.com</Link></p>

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default AR_Terms;
