import Axios from "../../../utils/axiosHelper";
// Get Agenct Details
export const fetchCategories = async (username) => {
  try {
    const response = await Axios({
      method: "get",
      url: `getServiceCategories`
    });
    return response; // Return the entire response object
  } catch (error) {
    throw error; // Rethrow the error to be caught by the caller
  }
};
// Get Country Details
export const fetchCountry = async () => {
  try {
    const response = await Axios({
      method: "get",
      url: `getAllCountries`
    });
    return response; // Return the entire response object
  } catch (error) {
    throw error; // Rethrow the error to be caught by the caller
  }
};
// Get Country Details
export const fetchCity = async (cid) => {
  try {
    const response = await Axios({
      method: "get",
      url: `getCityByCountry/${cid}`
    });
    return response; // Return the entire response object
  } catch (error) {
    throw error; // Rethrow the error to be caught by the caller
  }
};
// Get Agency Details
export const fetchStarts = async (formData) => {
  console.log(formData,)
  try {
    const response = await Axios({
      method: "post",
      url: `getListInfluenceProfiles`,
      data: formData
    });
    return response; // Return the entire response object
  } catch (error) {
    throw error; // Rethrow the error to be caught by the caller
  }
};
