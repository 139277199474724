
import { Col, Image, Row } from 'react-bootstrap';
import './Aboutus.css';

import React from "react";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalDetail from '../Header/ModalDetail';

const AR_StartCampaign = () => {
    const [showBrand, setShowBrand] = useState(false);
    const [showStar, setShowStar] = useState(false);
    const handleCloseBrand = () => setShowBrand(false);
    const handleShowBrand = () => setShowBrand(true);
    const handleCloseStar = () => setShowStar(false);
    const handleShowStar = () => setShowStar(true);
    return (
        <>
            <section className="startcampaign">
                <div className="container">
                    <div className="startcampaigncontent">
                        <h2>انطلق في حملتك الآن مع نجومك المفضلين!</h2>
                        <p>انضم إلينا في تشكيل مشهد التسويق عبر المؤثرين. اكتشف، تواصل، وازدهر مع نجمك حيث يلتقي التأثير بالتأثير.
                            انغمس في تجربة فريدة واحترافية، حيث نحن هنا لدعم نجاحك وضمان بقائك في الصدارة. اكتشف عالماً جديداً من الفرص وابدأ رحلتك نحو التأثير الفعّال.</p>
                        <Link to="javascript:void(0)" onClick={handleShowBrand}>ابدأ الآن <Icon icon="system-uicons:arrow-left" width="30px" height="30px" className='m-3' /></Link>
                    </div>

                </div>
            </section>
            <Modal show={showBrand} onHide={handleCloseBrand} size="lg"
                aria-labelledby="contained-modal-title-vcenter" centered>

                <Modal.Body>
                    <ModalDetail title={'Brand'} handleClose={handleCloseBrand} handleShow={handleShowStar}></ModalDetail>
                </Modal.Body>

            </Modal>
            <Modal show={showStar} onHide={handleCloseStar} size="lg"
                aria-labelledby="contained-modal-title-vcenter" centered>

                <Modal.Body>
                    <ModalDetail title={'Star'} handleClose={handleCloseStar} handleShow={handleShowBrand}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AR_StartCampaign;
