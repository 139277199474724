import React from 'react'
import InfluencerProfile from './InfluencerProfile';
import { Col, Form, Row } from "react-bootstrap";
function InfluencersList({ starsList, imagePath, Star, screenWidth }) {
    if (screenWidth < 575) {
        if (Star && Star.length > 0) {
          return Star.map((item, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={index}>
              <InfluencerProfile data={item} imagePath={imagePath} />
            </Col>
          ));
        } else {
          return starsList.map((item, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={index}>
              <InfluencerProfile data={item} imagePath={imagePath} />
            </Col>
          ));
        }
      } else {
        if (Star && Star.length > 0) {
          return Star.map((item, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={index}>
              <InfluencerProfile data={item} imagePath={imagePath} />
            </Col>
          ));
        } else {
          return starsList.map((item, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={index}>
              <InfluencerProfile data={item} imagePath={imagePath} />
            </Col>
          ));
        }
      }
}
export default InfluencersList
