// @ts-nocheck
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./navbar.module.css";
import ModalComponent from "component/Modal/Modal-Component";
/**
 * Renders the header (navbar) component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isProfile - Indicates if the header is for a profile.
 * @return {JSX.Element} The rendered header component.
 */
const Header = ({ isProfile }) => {
  const [showBrand, setShowBrand] = useState(false);
  const [showStar, setShowStar] = useState(false);
  const handleCloseBrand = () => setShowBrand(false);
  const handleShowBrand = () => setShowBrand(true);
  const handleCloseStar = () => setShowStar(false);
  const handleShowStar = () => setShowStar(true);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const { username } = useParams();
  /**
   * Toggles the "sidebar-show" class on the body element when the menu is clicked.
   *
   * @return {void} This function does not return anything.
   */
  const handleMenuClick = () => {
    const body = document.querySelector("body");
    body.classList.toggle("sidebar-show");
  };
  const language = localStorage.getItem("language");
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  /**
   * Toggles the language direction and updates the language in the local storage and i18n.
   *
   * @return {void} This function does not return anything.
   */
  const handleTogglelanguage = () => {
    const root = document.getElementById("root-html");
    if (language === "ar") {
      root.setAttribute("dir", "ltr");
      localStorage.setItem("dir", "ltr");
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    } else {
      root.setAttribute("dir", "rtl");
      localStorage.setItem("dir", "rtl");
      localStorage.setItem("language", "ar");
      i18n.changeLanguage("ar");
    }
  };
  /**
   * Handles the scroll event and updates the state of the component based on the scroll position in use effect hook of the component.
   *
   * @return {void} This function does not return anything.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Return statement checks if 'isProfile' is false, then renders the header
  return (
    !isProfile && (
      // Header component with conditional classes based on 'scrolled' state
      <header
        className={`rtl:flex-row-reverse flex opacity-100 ${
          scrolled ? "z-50 sticky" : "absolute z-50"
        }  items-center w-full top-0 transition-opacity ease-in-out duration-400`}
      >
        {/* Navbar component with conditional classes for expanded view and scrolling state */}
        <Navbar
          expand="lg"
          className={`w-full ${
            scrolled && "bg-body-tertiary"
          } sm:py-5 englishheader`}
        >
          <Container className="!gap-0">
            {/* Navbar brand/logo with a link to the homepage */}
            <Navbar.Brand href="/" className="!me-4 sm:!m-auto">
              <Image src="/Images/logo.svg" />
            </Navbar.Brand>
            {/* Navbar toggle button for mobile view */}
            <Navbar.Toggle
              aria-controls="navbarScroll"
              onClick={handleMenuClick}
            />
            {/* Collapsible section for navbar links and buttons */}
            <Navbar.Collapse id="navbarScroll" className="justify-between">
              {/* Navigation links with conditional styles */}
              <Nav
                className={`flex gap-6 sm:gap-1 items-center ${styles.nav} sm:!max-h-none sm:!h-auto sm:items-start`}
                navbarScroll
              >
                {/* Navigation link to the home page */}
                <Nav.Link className={styles.navItem} href="/">
                  {t("navbar.TextHome")}
                </Nav.Link>
                {/* Navigation link to the business page */}
                <Nav.Link className={styles.navItem} href="/listofinflancers">
                  {t("navbar.TextForBuisness")}
                </Nav.Link>
                {/* Navigation link that triggers a function to join as a star */}
                <Nav.Link className={styles.navItem} onClick={handleShowStar}>
                  {t("navbar.TextJoinAsStar")}
                </Nav.Link>
                {/* Navigation link that scrolls to the 'How it works' section */}
                <Nav.Link className={styles.navItem} href="#bussinesswork">
                  {t("navbar.TextHowItWorks")}
                </Nav.Link>
                {/* Language toggle link with an image and conditional text */}
                <Nav.Link
                  to="#"
                  className={`flex items-center gap-1 ${styles.navItem}`}
                  onClick={handleTogglelanguage}
                >
                  <Image src="/Images/english.svg" />
                  {language === "en" ? "AR" : "EN"}
                </Nav.Link>
              </Nav>
              {/* Button to download the app */}
              <Button
                variant="primary"
                className={`${styles.btn} sm:!border-none`}
                onClick={handleShowBrand}
              >
                {t("navbar.TextDownloadApp")}
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <ModalComponent
          isOpen={showStar}
          closeModal={handleCloseStar}
          openModal={handleShowStar}
          title={"Star"}
        />
      </header>
    )
  );
};
export default Header;
