// @ts-nocheck
import { Col, Form, Image, Row } from "react-bootstrap";
import "./DownloadNojom.css";
import { Link } from "react-router-dom/dist";
import { Icon } from "@iconify/react/dist/iconify";
import styles from "./downloadnojom.module.css";
import { useTranslation } from "react-i18next";
const DownloadNojom = () => {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="container sm:!max-w-full sm:!p-0 sm:!m-0 sm:!gy-0 sm:!gx-0">
          <div className={styles.downloadnojom}>
            <div className={styles.title}>
              <h3>{t("home.TitleDownloadTheNojomApp")}</h3>
            </div>
            <div className={styles.forBrand}>
              <div className={styles.subTitle}>
                <h4>{t("TextForBrands")}</h4>
              </div>
              <div className={styles.downloadBtns}>
                <div>
                  <Link
                    to="https://apps.apple.com/us/app/nojom-for-brands/id1488448062"
                    target={"_blank"}
                  >
                    <Image
                      src="/Images/App Store - Filled.svg"
                      className="rounded-[10px]"
                    />
                  </Link>
                </div>
                <div>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.nojom.brands&pli=1"
                    target={"_blank"}
                  >
                    <Image
                      src="/Images/Google Play.svg"
                      className="rounded-lg"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.forBrand}>
              <div className={styles.subTitle}>
                <h4>{t("TextForStars")}</h4>
              </div>
              <div className={styles.downloadBtns}>
                <div>
                  <Link
                    to="https://apps.apple.com/us/app/nojom-for-stars/id6463190220"
                    target={"_blank"}
                  >
                    <Image
                      src="/Images/App Store - Filled.svg"
                      className="rounded-[10px]"
                    />
                  </Link>
                </div>
                <div>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.nojom.stars"
                    target={"_blank"}
                  >
                    <Image
                      src="/Images/Google Play.svg"
                      className="rounded-lg"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.appModal}>
              <Image src="/Images/iPhone 16.svg" width={480} height={565} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DownloadNojom;
