
import { Col, Image, Row } from 'react-bootstrap';
import './PrivacyPolicy.css';

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


const AR_PrivacyPolicy = ({ setIsProfile }) => {
    useEffect(() => {
        setIsProfile(false)
    }, [0])
    return (
        <>
            <section className='privacy-policy'>
                <div className="container">
                    <div className="privacy-policy-title">
                        <h2>سياسة الخصوصية</h2>
                    </div>
                </div>
                <div className="privacy-policy-info ar">
                    <div className="container">
                        <div className="privacy-bg">
                            <div className="conditions">
                                <h3>1. مقدمة</h3>
                                <p>مرحبًا بك في نجوم! في نجوم، نلتزم بحماية خصوصيتك وضمان أمان معلوماتك الشخصية. توضح سياسة الخصوصية هذه كيفية جمعنا واستخدامنا وحماية بياناتك عند استخدامك لموقعنا وخدماتنا.</p>
                            </div>
                            <div className="conditions">
                                <h3>2. المعلومات التي نقوم بجمعھا</h3>
                                <p>نقوم بجمع المعلومات التي تقدمها بشكل طوعي، مثل اسمك وعنوان بريدك الإلكتروني وتفاصيل أخرى عند تسجيلك على منصتنا. بالإضافة إلى ذلك، قد نقوم بجمع بعض المعلومات تلقائيًا، بما في ذلك معلومات الجهاز وعناوين IP وأنماط الاستخدام.</p>
                            </div>
                            <div className="conditions">
                                <h3>3. كيفية استخدام معلوماتك</h3>
                                <p>نستخدم المعلومات التي تم جمعها لتقديم وتحسين خدماتنا، وتخصيص تجربتك، والتواصل معك بشأن الترويجات أو التحديثات. قد يتم استخدام بياناتك أيضًا لأغراض التحليل لتحسين منصتنا.</p>
                            </div>
                            <div className="conditions">
                                <h3>4. مشاركة معلوماتك</h3>
                                <p>نحن لا نبيع أو نتاجر أو نقوم بنقل معلوماتك الشخصية إلى أطراف خارجية. قد يتم مشاركة معلوماتك مع أطراف ثالثة موثوقة تساعدنا في تشغيل موقعنا، أو إجراء أعمالنا، أو خدمتك.</p>
                            </div>
                            <div className="conditions">
                                <h3>5. ملفات تعريف الارتباط وتقنيات التتبع المماثلة</h3>
                                <p>نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتحسين تجربتك على منصتنا. يمكنك اختيار تعطيل ملفات تعريف الارتباط من خلال إعدادات متصفحك، ولكن قد يؤثر ذلك على وظائف معينة.</p>
                            </div>
                            <div className="conditions">
                                <h3>6. روابط الطرف الثالث</h3>
                                <p>قد يحتوي موقعنا على روابط إلى مواقع ويب تابعة لأطراف ثالثة. ليس لدينا مسؤولية أو تبعية عن محتوى وأنشطة هذه المواقع المرتبطة. نشجعك على مراجعة سياسات الخصوصية الخاصة بهذه المواقع الخارجية.</p>
                            </div>
                            <div className="conditions">
                                <h3>7. خصوصية الأطفال</h3>
                                <p>نجوم ليست مخصصة للأفراد الذين تقل أعمارهم عن 13 عامًا. نحن لا نقوم عن عمد بجمع معلومات شخصية من الأطفال. إذا كنت تعتقد أننا قد قمنا بجمع معلومات عن طفل دون سن 13 عامًا عن طريق الخطأ، يرجى الاتصال بنا لإزالة هذه المعلومات.</p>
                            </div>
                            <div className="conditions">
                                <h3>8. تغييرات في سياسة الخصوصية</h3>
                                <p>قد نقوم بتحديث سياسة الخصوصية لدينا من وقت لآخر، وسيتم عرض أي تغييرات على هذه الصفحة. نشجعكم على مراجعة سياسة الخصوصية هذه بشكل دوري للحصول على أي تحديثات.</p>
                            </div>

                            <p>الرجاء إرسال أي أسئلة حول سياسة الخصوصية إلى البريد الإلكتروني:<Link>support@nojom.com</Link></p>

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default AR_PrivacyPolicy;
