// @ts-nocheck
import React, { Fragment, useEffect, useState, useTransition } from "react";
import { toast } from "react-toastify";
import { getAgentPartners } from "./action";
import { jwtDecode } from "jwt-decode";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionTitle from "./SectionTitle";
import Loader from "component/Loader/Loader";
import Sperarator from "./Seperator";
function UserMainPartners({ id }) {
  const [userAgentPartners, setUserAgentPartners] = useState({
    data: [],
    page_no: 1,
    loading: true
  });
  const getUserAgentPartners = async () => {
    setUserAgentPartners({ ...userAgentPartners, loading: true });
    await getAgentPartners(id, userAgentPartners.page_no)
      .then((res) => {
        const data = jwtDecode(res.data.data);
        setUserAgentPartners({
          ...userAgentPartners,
          data: data,
          loading: false
        });
      })
      .catch(() => {
        setUserAgentPartners({
          ...userAgentPartners,
          loading: false
        });
      });
  };
  useEffect(() => {
    getUserAgentPartners();
  }, [userAgentPartners.page_no]);
  const { i18n } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [_, startTransition] = useTransition();
  const handleNext = () => {
    startTransition(() => {
      setUserAgentPartners((prevState) => ({
        ...prevState,
        page_no: prevState.page_no + 1
      }));
    });
  };
  const handlePrev = () => {
    if (userAgentPartners?.page_no >= 2) {
      startTransition(() => {
        setUserAgentPartners((prevState) => ({
          ...prevState,
          page_no: prevState.page_no - 1
        }));
      });
    }
  };
  const handleCopyCode = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        toast.success(
          <div>
            <span>Code {`(${code})`} copied to clipboard.</span>
          </div>
        );
      })
      .catch((error) => {
        console.error("Error copying text:", error);
        toast.error("An error occurred while copying link. Please try again!");
      });
  };
  const ItemsAmount = userAgentPartners?.page_no * 4;
  return (
    <Fragment>
      {userAgentPartners?.data?.length === 0 ? null : (
        <Fragment>
          <Sperarator />
          <section className="section">
            <SectionTitle
              title="Partners"
              handleNext={handleNext}
              handlePrev={handlePrev}
              isNextDisabled={userAgentPartners?.data?.count <= ItemsAmount}
              isPrevDisabled={userAgentPartners?.page_no <= 1}
            />
            <Row className="gy-3 gx-3">
              {userAgentPartners?.data?.data?.map((item, index) => (
                <Col xs="12" md="6">
                  <div className="partner-item rtl:!flex-row">
                    <div className="partner-img">
                      <Image
                        src={`${userAgentPartners?.data.path}${item?.filename}`}
                      />
                    </div>
                    <div className="partner-info rtl:!items-start">
                      <div className="partner-name">
                        <h5>
                          {i18n.language === "ar" ? item?.name_ar : item?.name}
                        </h5>
                      </div>
                      <div className="partner-btn">
                        <Link to={item?.link} className="btn">
                          Download App
                        </Link>
                      </div>
                      {item?.code !== null && (
                        <div className="copy-copon-container rtl:!flex-row">
                          <div
                            className="icon-container cursor-pointer"
                            onClick={() => handleCopyCode(item?.code)}
                          >
                            <Image
                              src={
                                process.env.PUBLIC_URL + "/Images/Copy-icon.svg"
                              }
                              alt="copy-icon"
                            />
                          </div>
                          <div className="copon-info">
                            <p>Copy Coupon {`(${item?.code})`}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
}
export default UserMainPartners;
