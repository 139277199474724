// @ts-nocheck
import { Image } from "react-bootstrap";
const SectionTitle = ({
  title,
  withIcon = true,
  handleNext,
  handlePrev,
  isNextDisabled,
  isPrevDisabled
}) => {
  return (
    <div className="header-title">
      <div className="title">
        <h3>{title}</h3>
      </div>
      {withIcon && (
        <div className="arrows">
          <button
            disabled={isPrevDisabled}
            onClick={handlePrev}
            className={`cursor-pointer ${
              isPrevDisabled ? "opacity-50" : "opacity-100"
            }`}
          >
            <Image
              src={process.env.PUBLIC_URL + "/Images/Alt Arrow Left.svg"}
              alt="left-icon"
              className="rtl:rotate-180"
            />
          </button>
          <button
            disabled={isNextDisabled}
            onClick={handleNext}
            className={`cursor-pointer ${
              isNextDisabled ? "opacity-50" : "opacity-100"
            }`}
          >
            <Image
              src={process.env.PUBLIC_URL + "/Images/Alt Arrow Right.svg"}
              alt="right-icon"
              className="rtl:rotate-180"
            />
          </button>
        </div>
      )}
    </div>
  );
};
export default SectionTitle;
