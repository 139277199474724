import React from "react";
import { Route, Routes } from "react-router-dom";
import AR_ListofInflancers from "pages/AR_ListofInflancers/AR_ListofInflancers";
import AR_ContactUs from "pages/AR_ContactUs/AR_ContactUs";
import AR_PrivacyPolicy from "pages/AR_PrivacyPolicy/AR_PrivacyPolicy";
import AR_Terms from "pages/AR_Terms/AR_Terms";
import Terms from "pages/Terms/Terms";
import AboutUs from "pages/AboutUs/AboutUs";
import AR_AboutUs from "pages/AR_AboutUs/AR_AboutUs";
import ContactUs from "pages/ContactUs/ContactUs";
import ListofInflancers from "pages/ListofInflancers/ListofInflancers";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import UserProfile from "pages/UserProfile/UserProfile";
import HomePage from "pages/HomePage/HomePage";
function Router({ setIsProfile, setLang }) {
  return (
    <Routes>
      <Route path="/" element={<HomePage setIsProfile={setIsProfile} />} />
      <Route
        path="/listofinflancers"
        element={<ListofInflancers setIsProfile={setIsProfile} />}
      />
      <Route
        path="/:username/"
        element={<UserProfile setIsProfile={setIsProfile} setLang={setLang} />}
      />
      <Route
        path="/privacypolicy"
        element={<PrivacyPolicy setIsProfile={setIsProfile} />}
      />
      <Route
        path="/privacypolicy"
        element={<AR_PrivacyPolicy setIsProfile={setIsProfile} />}
      />
      <Route path="/terms" element={<AR_Terms setIsProfile={setIsProfile} />} />
      <Route path="/terms" element={<Terms setIsProfile={setIsProfile} />} />
      <Route
        path="/aboutus"
        element={<AboutUs setIsProfile={setIsProfile} />}
      />
      <Route
        path="/aboutus"
        element={<AR_AboutUs setIsProfile={setIsProfile} />}
      />
      <Route
        path="/contactus"
        element={<ContactUs setIsProfile={setIsProfile} />}
      />
      <Route
        path="/contactus"
        element={<AR_ContactUs setIsProfile={setIsProfile} />}
      />
      <Route path="/faqs" element={<ContactUs setIsProfile={setIsProfile} />} />
    </Routes>
  );
}
export default Router;
