// import axios from "axios";
// const API_URL = process.env.React_App_API_URL;
// const Axios = axios.create({
//   baseURL: API_URL
// });
// axios.interceptors.request.use(
//   function (config) {
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
// // Add a response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     q;
//     return response;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
// export default Axios;
import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL;
// const API_URL =
//   "https://kpoe1rj2uh.execute-api.me-central-1.amazonaws.com/backend";
// const Token = `Bearer ${localStorage.getItem("Token")}`
const Axios = axios.create({
  baseURL: API_URL
});
// export const setAuthHeader = (token) => {
//     Axios.defaults.headers.common.Authorization =
//         token || Token;
// };
// Axios.defaults.headers.common.Authorization = Token
//if a 401 happens, the user will be logged out
Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("Token");
    }
    return Promise.reject(error);
  }
);
// Set the initial header from storage or something (should surround with try catch in actual app)
// setAuthHeader(Token);
export default Axios;
