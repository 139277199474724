
import { Col, Image, Row } from 'react-bootstrap';
import './Aboutus.css';

import React from "react";


const AR_WhyNojom = () => {
    return (
        <>
            <section className="whynojomsection">
                <div className="container">
                    <div className="whynojomcontent">
                        <h2>لماذا نجوم؟</h2>
                        <p>نجوم توفر لك كل الأدوات التي تحتاجها للسيطرة على حملتك الإعلانية وتوسيع نطاق عملك بنجاح. اكتشف قوة التحكم والتوسع مع nojom!</p>
                    </div>
                    <Row>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojomchaticon.svg' /></span>
                                <h3>تواصل بلا حواجز</h3>
                                <p>نحن نعتبر الاتصال الشفاف والفعّال أمرًا أساسيًا، نسعى جاهدين لتسهيل التواصل الحقيقي.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojompaymenticon.svg' /></span>

                                <h3>حلول الدفع الآمنة</h3>

                                <p> استمتع بسهولة التسوق واطمئن إلى أمان معاملاتك، حيث نحرص على الحفاظ على سرية بياناتك وتوفير تجربة دفع موثوقة.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojomdiverceicon.svg' /></span>
                                <h3>تشكيلة متنوعة من المؤثرين</h3>

                                <p>استكشف تشكيلة مدروسة من المؤثرين.
                                    اترك لنا رعاية اختيار المؤثر المثالي الذي يلبي توقعاتك بكل دقة وابداع، مع الحفاظ على جودة التواصل والتأثير الإيجابي.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="ourmission nojomtitle">
                                <span><Image src='/Images/nojomtrialicon.svg' /></span>
                                <h3>تجربة سهلة وجذابة للمستخدم</h3>

                                <p>استكشف منصتنا بسهولة مع تطبيق الهاتف المحمول الذي صمم ليوفر لك تجربة فريدة وسلسة.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >

        </>
    );
}

export default AR_WhyNojom;
