
import { Col, Image, Row } from 'react-bootstrap';
import './AboutUs.css';

import React, { useEffect } from "react";
import AR_AboutusHero from '../../component/AR_Aboutus/AR_AboutusHero';
import AR_WhyNojom from '../../component/AR_Aboutus/AR_WhyNojom';
import AR_StartCampaign from '../../component/AR_Aboutus/AR_StartCampaign';


const AR_AboutUs = ({ setIsProfile }) => {
    useEffect(() => {
        setIsProfile(false)
    }, [0])
    return (
        <>
            <div className="aboutusmain">
                <AR_AboutusHero />
                <AR_WhyNojom></AR_WhyNojom>
                <AR_StartCampaign></AR_StartCampaign>
            </div>
        </>
    );
}

export default AR_AboutUs;
