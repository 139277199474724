
import { Col, Image, Row } from 'react-bootstrap';
import './Terms.css';

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


const Terms = ({setIsProfile}) => {
    useEffect(() => {
        setIsProfile(false)
    }, [0])
    return (
        <>
<section className='privacy-policy'>
    <div className="container">
        <div className="privacy-policy-title">
            <h2>Terms and Conditions</h2>
        </div>
    </div>
    <div className="privacy-policy-info">
        <div className="container">
            <div className="privacy-bg">
            <div className="conditions">
     
                    <p>Last Updated: January, 2024<br></br>
Welcome to nojom! These Terms and Conditions outline the rules and regulations for using our platform. By accessing or using nojom, you agree to abide by these terms. Please read them carefully.</p>
                </div>
                <div className="conditions">
                    <h3>1. Acceptance of Terms</h3>
<p>By using nojom, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using the platform.</p>                </div>
                <div className="conditions">
                    <h3>2. Registration and Account</h3>
                    <p>To access certain features of nojom, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and agree to notify us immediately of any unauthorized use.</p>
                </div>
                <div className="conditions">
                    <h3>3. User Content</h3>
                    <p>You retain ownership of the content you submit to nojom. By posting content, you grant nojom a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, display, reproduce, and distribute your content.</p>
                </div>
                <div className="conditions">
                    <h3>4. Prohibited Activities</h3>
                    <p>You agree not to engage in any unlawful or prohibited activities on nojom. This includes, but is not limited to, violating intellectual property rights, transmitting viruses, or engaging in fraudulent activities.</p>
                </div>
                <div className="conditions">
                    <h3>5. Intellectual Property</h3>
                    <p>nojom and its original content, features, and functionality are the exclusive property of nojom. You may not reproduce, distribute, modify, create derivative works, publicly display, or otherwise exploit any content without our prior written consent.</p>
                </div>
                <div className="conditions">
                    <h3>6. Privacy Policy</h3>
                    <p>Your use of nojom is also governed by our <Link to="/privacypolicy"> Privacy Policy</Link>. Please review this policy to understand how we collect, use, and protect your information.</p>
                </div>
                <div className="conditions">
                    <h3>7. Termination of Services</h3>
                    <p>We reserve the right to terminate or suspend your access to nojom at our discretion, without notice, for any reason, including a breach of these Terms and Conditions.</p>
                </div>
                <div className="conditions">
                    <h3>8. Changes to Terms</h3>
                    <p>We may update these Terms and Conditions from time to time. Any changes will be effective immediately upon posting. Continued use of nojom after such changes constitutes your acceptance of the revised terms.</p>
                </div>
                <div className="conditions">
                    <h3>9. Governing Law</h3>
                    <p>These Terms and Conditions are governed by and constructed in accordance with the laws of the Kingdom of Saudi Arabia, without regard to its conflict of law provisions.</p>
                </div>
                <p>.Please send any questions about the privacy policy to <Link to="mailto:support@nojom.com">support@nojom.com</Link></p>

            </div>
        </div>
    </div>
</section>

        </>
    );
}

export default Terms;
