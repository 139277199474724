import { Col, Image, Row } from "react-bootstrap";
import "./AboutUs.css";

import React, { useEffect } from "react";
import AboutusHero from "../../component/Aboutus/AboutusHero";
import WhyNojom from "../../component/Aboutus/WhyNojom";
import StartCampaign from "../../component/Aboutus/StartCampaign";

const AboutUs = ({ setIsProfile }) => {
  useEffect(() => {
    setIsProfile(false);
  }, [0]);
  return (
    <>
      <AboutusHero />

      <WhyNojom></WhyNojom>
      <StartCampaign></StartCampaign>
    </>
  );
};

export default AboutUs;
