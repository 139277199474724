// @ts-nocheck
import React, { useState, useTransition, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import SectionTitle from "./SectionTitle";
import { getAgentStore } from "./action";
import { jwtDecode } from "jwt-decode";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "component/Loader/Loader";
import Sperarator from "./Seperator";
function UserMainStores({ id }) {
  const [userAgentStore, setUserAgentStore] = useState({
    data: [],
    page_no: 1,
    loading: true
  });
  const getUserAgentStore = async () => {
    setUserAgentStore({
      ...userAgentStore,
      loading: true
    });
    await getAgentStore(id, userAgentStore.page_no)
      .then((res) => {
        const data = jwtDecode(res.data.data);
        setUserAgentStore({
          ...userAgentStore,
          data: data,
          loading: false
        });
      })
      .catch(() => {
        setUserAgentStore({
          ...userAgentStore,
          loading: false
        });
      });
  };
  useEffect(() => {
    getUserAgentStore();
  }, [userAgentStore.page_no]);
  const { i18n } = useTranslation();
  const [_, startTransition] = useTransition();
  const handleNext = () => {
    startTransition(() => {
      setUserAgentStore((prevState) => ({
        ...prevState,
        page_no: prevState.page_no + 1
      }));
    });
  };
  const handlePrev = () => {
    if (userAgentStore?.page_no >= 2) {
      startTransition(() => {
        setUserAgentStore((prevState) => ({
          ...prevState,
          page_no: prevState.page_no - 1
        }));
      });
    }
  };
  const ItemsAmount = userAgentStore?.page_no * 6;
  if (userAgentStore?.data.length === 0) return null;
  return (
    <Fragment>
      <Sperarator />
      <section className="section">
        <SectionTitle
          title="My Stores"
          handleNext={handleNext}
          handlePrev={handlePrev}
          isNextDisabled={userAgentStore?.data?.count <= ItemsAmount}
          isPrevDisabled={userAgentStore?.page_no <= 1}
        />
        <div>
          <Row className="gy-3 gx-3">
            {userAgentStore?.data?.data?.map((item, index) => (
              <Col xs="12" md="6">
                <Link to={item?.link}>
                  <div className="store-item">
                    <Image
                      src={`${userAgentStore?.data.path}${item?.filename}`}
                      alt="store-image"
                    />
                    <div className="btn-container left-btn-container rtl:!right-[26px]">
                      <Link className="btn" to={item?.link}>
                        {item?.title}
                      </Link>
                    </div>
                    <Link className="icon-container" to={item?.link}>
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          "/Images/Round Arrow Right Up.svg"
                        }
                        alt="top-arrow-icon"
                      />
                    </Link>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </Fragment>
  );
}
export default UserMainStores;
