// @ts-nocheck
import { Col, Image, Row } from "react-bootstrap";
import "./Header.css";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./modalDetails.module.css";
const ModalDetail = ({ title, handleClose, handleShow }) => {
  console.log(title);
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.language === "en" ? (
        <div className="flex items-center justify-center flex-col gap-10 py-16">
          <h3 className={styles.mainTitle}>
            Download <span>{title}</span> App{" "}
          </h3>
          {title === "Brand" ? (
            <p className={styles.mainText}>
              Download our app to sign up and <b>discover, compare, chat</b>{" "}
              with, and <b>collaborate</b> with <b>Stars</b>
            </p>
          ) : (
            <p className={styles.mainText}>
              Download our app to sign up and{" "}
              <b>discover, compare, chat and collaborate</b> with influencers
              and celebrities
            </p>
          )}
          <div className="downloadbtn gap-4 d-flex justify-content-center">
            <Link
              to={
                title === "Brand"
                  ? "https://apps.apple.com/us/app/nojom-for-brands/id1488448062"
                  : "https://apps.apple.com/us/app/nojom-for-stars/id6463190220"
              }
              target="_blank"
            >
              <Image src="/Images/modalappstorebtn.svg" />
            </Link>
            <Link
              to={
                title === "Brand"
                  ? "https://play.google.com/store/apps/details?id=com.nojom.brands&pli=1"
                  : "https://play.google.com/store/apps/details?id=com.nojom.stars"
              }
              target="_blank"
            >
              <Image src="/Images/modalplaystorebtn.svg" />
            </Link>
          </div>
          <span className={styles.mainLink}>
            Click here to Download{" "}
            <Link
              to="#"
              onClick={() => {
                handleShow();
                handleClose();
              }}
            >
              {" "}
              {title === "Brand" ? "Stars" : "Brands"} App
            </Link>{" "}
          </span>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col gap-10 py-16">
          <h3 className={styles.mainTitle}>
            {" "}
            قم بتحميل تطبيق{" "}
            <span>{title === "Brand" ? "العلامة التجارية" : "النجوم"}</span>
          </h3>
          <p className={styles.mainText}>
            <b>واكتشاف، مقارنة، الدردشة، والتعاون</b> حمل تطبيقنا للتسجيل مع
            النجوم.
          </p>
          <div className="downloadbtn gap-4 d-flex justify-content-center">
            <Link
              to={
                title === "Brand"
                  ? "https://apps.apple.com/us/app/nojom-for-brands/id1488448062"
                  : "https://apps.apple.com/us/app/nojom-for-stars/id6463190220"
              }
              target="_blank"
            >
              <Image src="/Images/modalappstorebtn.svg" />
            </Link>
            <Link
              to={
                title === "Brand"
                  ? "https://play.google.com/store/apps/details?id=com.nojom.brands&pli=1"
                  : "https://play.google.com/store/apps/details?id=com.nojom.stars"
              }
              target="_blank"
            >
              <Image src="/Images/modalplaystorebtn.svg" />
            </Link>
          </div>
          <span className={styles.mainLink}>
            قم بتحميل تطبيق{" "}
            <Link
              to="#"
              onClick={() => {
                handleShow();
                handleClose();
              }}
            >
              {" "}
              {title === "Brand" ? "النجوم" : "العلامات التجارية"}
            </Link>{" "}
          </span>
        </div>
      )}
    </>
  );
};
export default ModalDetail;
