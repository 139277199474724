// @ts-nocheck
import React, { Fragment, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import Loader from "component/Loader/Loader";
import SectionTitle from "./SectionTitle";
import Sperarator from "./Seperator";
function UserMainOverview() {
  return (
    <Fragment>
      <Sperarator />
      <section className="section">
        <SectionTitle title="Overview" withIcon={false} />
        <div className="overview-row sm:gap-4 rtl:flex-row sm:!flex-col">
          <InfoContainer />
          <Sperarator classname={"aboutme-seperator"} />
          <CategoryContainer />
          <Sperarator classname={"aboutme-seperator"} />
          <SubInfoContainer
            title1={"Mawthouq"}
            text1={"123456"}
            title2={"Price Range"}
            text2={"2,000 - 6,500 SAR"}
            withIcon={true}
          />
          <Sperarator classname={"aboutme-seperator"} />
          <SubInfoContainer
            title1={"Age"}
            text1={"23"}
            title2={"Gender"}
            text2={"Female"}
          />
        </div>
      </section>
    </Fragment>
  );
}
export default UserMainOverview;
const InfoContainer = () => {
  const [isTextShow, setIsTextShow] = useState(false);
  return (
    <div className="about-me flex flex-col gap-2">
      <div>
        <h5>About me</h5>
      </div>
      <div className="flex flex-wrap">
        <p className={isTextShow ? "" : "line-clamp-1 w-[85%] sm:w-[73%]"}>
          Lorem ipsum is placeholder text commonly used text commonly used in
          the gra lorem ipsum is placeholder text Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Aspernatur sapiente omnis, qui quod,
          unde alias minima laborum, tempore magni aliquam explicabo dicta
          maiores beatae accusamus! Labore rem alias aut incidunt...{" "}
          <span
            className={isTextShow ? "" : "hidden"}
            onClick={() => setIsTextShow(false)}
          >
            show less
          </span>
        </p>
        <span
          className={isTextShow ? "hidden" : ""}
          onClick={() => setIsTextShow(true)}
        >
          read more
        </span>
      </div>
    </div>
  );
};
const CategoryContainer = () => {
  return (
    <div className="category-me flex flex-col gap-2">
      <div>
        <h5>Category</h5>
      </div>
      <div className="flex flex-wrap gap-3 categories">
        {Array.from({ length: 18 }).map((_, index) => (
          <div className="category" key={index}>
            <p className={`${index === 0 ? "main" : ""}`}>tag</p>
          </div>
        ))}
      </div>
    </div>
  );
};
const SubInfoContainer = ({ title1, text1, title2, text2, withIcon }) => {
  return (
    <Row className="w-full sm:gap-4 gx-0">
      <Col xs={12} sm={6}>
        <div className="sub-info flex flex-col gap-2">
          <div className={withIcon ? "flex gap-1 items-center" : ""}>
            <h5>{title1}</h5>
            {withIcon && (
              <div>
                <Image
                  src={process.env.PUBLIC_URL + "/Images/Medal Star.svg"}
                />
              </div>
            )}
          </div>
          <div>
            <p>{text1}</p>
          </div>
        </div>
      </Col>
      <Sperarator classname={"aboutme-seperator sm:block hidden"} />
      <Col xs={12} sm={6}>
        <div className="sub-info flex flex-col gap-2">
          <div>
            <h5>{title2}</h5>
          </div>
          <div>
            <p>{text2}</p>
          </div>
        </div>
      </Col>
    </Row>
  );
};
