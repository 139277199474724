// @ts-nocheck
import React, { useEffect, useState, cache, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { getAgentByUsername } from "./action";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { Image } from "react-bootstrap";
import Loader from "component/Loader/Loader";
function UserMainInfo({ handleShowBrand, handleShowStar }) {
  const { username } = useParams();
  const [userInfo, setUserInfo] = useState({
    data: {},
    loading: true
  });
  const getUserInfo = async () => {
    await getAgentByUsername(username).then((res) => {
      setUserInfo({
        ...userInfo,
        loading: true
      });
      const data = jwtDecode(res.data.data);
      const user = {
        enName: data.first_name,
        arName: data.last_name,
        mawthooqId: data.mawthooq_id,
        isMawthooq: data.is_mawthooq_number,
        url: `nojom.com/${username}`,
        whatsApp: data.whatsapp_number,
        email: data.email,
        socialmedia: data.social_platform,
        userImg: data.path + data.profile_pic
      };
      setUserInfo({
        data: user,
        loading: false
      });
    });
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <Fragment>
      {userInfo.loading ? (
        <Loader />
      ) : (
        <Fragment>
          <UserProfileImage userImg={userInfo?.data?.userImg} />
          <UserInfo user={userInfo} />
          <UserTags
            user={userInfo}
            handleShowBrand={handleShowBrand}
            handleShowStar={handleShowStar}
          />
          <UserSocial socialmedia={userInfo?.data?.socialmedia} />
        </Fragment>
      )}
    </Fragment>
  );
}
export default UserMainInfo;
const UserProfileImage = ({ userImg }) => {
  return (
    <div className="profilemainimg">
      <Image src={userImg} alt="user profile image" />
    </div>
  );
};
const UserInfo = ({ user }) => {
  const { username } = useParams();
  const { i18n } = useTranslation();
  return (
    <div className="userInfo">
      <div className="user-username">
        {user?.data?.mawthooqId && (
          <div className="username">
            <div>
              <p>Mawthouq: {user.data.mawthooqId}</p>
            </div>
          </div>
        )}
        <div className="user-details">
          <div>
            <h2 className="sm:!text-[32px]">
              {i18n.language === "ar" ? user?.data?.arName : user?.data?.enName}
            </h2>
          </div>
          <div>
            <i>
              <Icon icon="ph:seal-check-fill" />
            </i>
          </div>
        </div>
        <div className="user-url">
          <h6>
            <Link to={`https://www.nojom.com/${username}`}>
              {user.data.url}
            </Link>
          </h6>
        </div>
      </div>
    </div>
  );
};
const UserTags = ({ user, handleShowBrand, handleShowStar }) => {
  return (
    <div className="profile-tag-info">
      <div className="social-btns flex-row rtl:!flex-row-reverse">
        {user.data.whatsApp !== null && (
          <div>
            <Link
              className="btn btn-gray"
              to={`https://api.whatsapp.com/send?phone=${user?.data?.whatsApp}`}
            >
              Whats App
            </Link>
          </div>
        )}
        {user.data.email !== null && (
          <div>
            <Link className="btn btn-gray" to={`mailto:${user?.data?.email}`}>
              Email
            </Link>
          </div>
        )}
        <div>
          <Link className="btn btn-gray" to="#" onClick={handleShowStar}>
            Message
          </Link>
        </div>
        <div className="d-none d-sm-block">
          <Link className="btn btn-black2" to="#" onClick={handleShowBrand}>
            Send Offer
          </Link>
        </div>
      </div>
      <div className="d-sm-none d-block">
        <Link className="btn btn-black2" to="#" onClick={handleShowBrand}>
          Send Offer
        </Link>
      </div>
    </div>
  );
};
const UserSocial = ({ socialmedia }) => {
  const { i18n } = useTranslation();
  const formatFollowers = (followers) => {
    if (followers >= 1000000) {
      return (
        <div className="flex rtl:flex-row-reverse gap-1 items-center">
          <p>{(followers / 1000000).toFixed(1)}</p>
          <p>{i18n.language === "ar" ? "مليون" : "M"}</p>
        </div>
      );
    } else if (followers >= 1000) {
      return (
        <div className="flex rtl:flex-row-reverse gap-1 items-center">
          <p>{(followers / 1000).toFixed(1)}</p>
          <p>{i18n.language === "ar" ? "ألف" : "K"}</p>
        </div>
      );
    } else {
      return (
        <div className="flex rtl:flex-row-reverse gap-1 items-center">
          <p>{followers.toString()}</p>
          <p>{i18n.language === "ar" ? "متابع" : "follower"}</p>
        </div>
      );
    }
  };
  return (
    <div className="profile-social-containers">
      {socialmedia?.map((item, index) => (
        <div className="social-item" key={index}>
          <div className="social-item-icon-info">
            <div>
              <Image
                src={item?.filename_gray}
                alt="x-icon"
                width={24}
                height={24}
              />
            </div>
            <div>
              <p>{i18n.language === "ar" ? item?.nameAr : item?.name}</p>
            </div>
          </div>
          <div className="social-item-icon-info justify-center items-center">
            {item.followers !== 0 && formatFollowers(item?.followers)}
            <Link to={`${item?.web_url}${item?.username}`}>
              <Image
                src={process.env.PUBLIC_URL + "/Images/Linear.svg"}
                alt="arrow-icon"
                width={16}
                className="rtl:rotate-180"
                height={16}
              />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
