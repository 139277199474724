import { Button, Col, Form, Image, Row } from "react-bootstrap";
import './ContactUs.css';
import { addContactForm } from "./action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AR_ContactUs = ({ setIsProfile }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !msg) {
      alert("Please fill in all fields.");
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      alert("Invalid email address.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("message", msg);

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      await addContactForm(formData, config).then((res) => {
        toast.success(res.data.message);
      });
    } catch (error) {
      // console.log("Error fetching contact details:",error);
      toast.error(error);
    }

    setName("");
    setEmail("");
    setMsg("");
  };
  useEffect(() => {
    setIsProfile(false);
  }, [0]);
  return (
    <>
      <section className="contactus ar">
        <div className="container">
          <div className="contactform">
            <Row>
              <Col lg={7}>
                <div className="contactformtitle">
                  <h3>نحن هنا لنسمع منك!</h3>
                  <p>نحن هنا من أجلك! كيف يمكننا مساعدتك اليوم؟</p>
                </div>
                <Form className="mr-0" onSubmit={onSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>الاسم</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ادخل اسمك"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>البريد الإلكتروني </Form.Label>
                    <Form.Control
                      type="mail"
                      placeholder="ادخل بريدك الإلكتروني"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Label>رسالتك</Form.Label>
                    <textarea
                      placeholder="اكتب رسالتك الآن "
                      name="msg"
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                      required
                    ></textarea>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    أرسل الآن
                  </Button>
                </Form>
              </Col>
              <Col lg={5}>
                <div className="contactdetail">
                  <span className="arcontactimg">
                    <Image src="/Images/contactimg.png" />
                  </span>
                  <ul className="contact ar">
                    <li>
                      <Link to="" className="text-english">
                        <span>
                          <Image src="/Images/location.svg"></Image>
                        </span>
                        Al Mughrizat, King Abdullah Branch Road, Riyadh
                      </Link>
                    </li>
                    <li className="arphonenumber text-english">
                      <Link to="">+966 50 247 5630</Link>
                      <span>
                        <Image src="/Images/phone.svg"></Image>
                      </span>
                    </li>
                    <li>
                      <Link to="" className="text-english">
                        <span>
                          <Image src="/Images/mail.svg"></Image>
                        </span>
                        support@nojom.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default AR_ContactUs;
