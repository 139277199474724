
import { Col, Image, Row } from 'react-bootstrap';
import './PrivacyPolicy.css';

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


const PrivacyPolicy = ({setIsProfile}) => {
    useEffect(() => {
        setIsProfile(false)
    }, [0])
    return (
        <>
<section className='privacy-policy'>
    <div className="container">
        <div className="privacy-policy-title">
            <h2>Privacy Policy</h2>
        </div>
    </div>
    <div className="privacy-policy-info">
        <div className="container">
            <div className="privacy-bg">
                <div className="conditions">
                    <h3>1. Introduction</h3>
                    <p>Welcome to nojom! At nojom, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safe guard your data when you use our website and services.</p>
                </div>
                <div className="conditions">
                    <h3>2. Information We Collect</h3>
                    <p>We collect information that you provide voluntarily, such as your name, email address, and other details when you register on our platform. Additionally, we may automatically collect certain information, including device information, IP addresses, and usage patterns.</p>
                </div>
                <div className="conditions">
                    <h3>3. How We Use Your Information</h3>
                    <p>We use the collected information to provide and improve our services, personalize your experience, and communicate with you about promotions or updates. Your data may also be used for analytics purposes to enhance our platform.</p>
                </div>
                <div className="conditions">
                    <h3>4. Sharing Your Information</h3>
                    <p>We do not sell, trade, or transfer your personally identifiable information to outside parties. Your information may be shared with trusted third parties who assist us in operating our website, conducting our business, or servicing you.</p>
                </div>
                <div className="conditions">
                    <h3>5. Data Security</h3>
                    <p>We implement a variety of security measures to maintain the safety of your personal information. All transactions are processed through secure gateways, and we follow industry best practices to protect your data.</p>
                </div>
                <div className="conditions">
                    <h3>6. Cookies and Tracking Technologies</h3>
                    <p>We use cookies and similar tracking technologies to enhance your experience on our platform. You can choose to disable cookies through your browser settings, but this may affect the functionality of certain features.</p>
                </div>
                <div className="conditions">
                    <h3>7. Third-Party Links</h3>
                    <p>Our website may contain links to third-party websites. We have no responsibility or liability for the content and activities of these linked sites. We encourage you to review the privacy policies of these third-party sites.</p>
                </div>
                <div className="conditions">
                    <h3>8. Children's Privacy</h3>
                    <p>Nojom is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us to remove the information.</p>
                </div>
                <div className="conditions">
                    <h3>9. Changes to This Privacy Policy</h3>
                    <p>We may update our Privacy Policy from time to time, and any changes will be reflected on this page. We encourage you to review this Privacy Policy periodically for any updates.</p>
                </div>
                <p>.Please send any questions about the privacy policy to <Link to="mailto:support@nojom.com">support@nojom.com</Link></p>

            </div>
        </div>
    </div>
</section>

        </>
    );
}

export default PrivacyPolicy;
