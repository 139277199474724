import { Col, Image, Row } from "react-bootstrap";
import "./ListofInflancers.css";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Inflancers from "../../component/ListofInflancers/Inflancers/Inflancers";
const ListofInflancers = ({ setIsProfile }) => {
  useEffect(() => {
    setIsProfile(false);
  }, [0]);
  return (
    <>
      <Inflancers></Inflancers>
    </>
  );
};
export default ListofInflancers;
