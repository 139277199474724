
import { Col, Image, Row } from 'react-bootstrap';
import './Aboutus.css';

import React from "react";


const AboutusHero = () => {
    return (
        <>
            <section className="aboutussection">
                <div className="container">
                    <div className="aboutcontent">
                        <h2>About Us</h2>
                        <p>At nojom, we consider ourselves more than just a platform; we are architects of meaningful connections between influencers and brands.</p>
                        <p> Our goal is to redefine the influencer marketing landscape by providing a seamless, secure, and innovative space for collaboration.</p>
                    </div>
                    <Row>
                        <Col>
                            <div className="ourmission">
                                <h3>Our Mission:</h3>
                                <p>Empower influencers and brands through seamless communication, secure payment solutions, and a diverse pool of influencers spanning all niches.</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="ourmission">
                                <h3>Our Vision:</h3>
                                <p>Be the catalyst for positive transformations in influencer marketing, fostering a community where creativity, trust, and success converge.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >

        </>
    );
}

export default AboutusHero;
