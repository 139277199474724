import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalDetail from "../Header/ModalDetail";
function ModalComponent({ isOpen, openModal, closeModal, title }) {
  return (
    <Modal
      show={isOpen}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <ModalDetail
          title={title}
          handleClose={closeModal}
          handleShow={openModal}
        ></ModalDetail>
      </Modal.Body>
    </Modal>
  );
}
export default ModalComponent;
