// @ts-nocheck
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useContext } from "react";
import styles from "./FAQ.module.css";
import { Link } from "react-router-dom/dist";
import { Icon } from "@iconify/react/dist/iconify";
import AccordionContext from "react-bootstrap/AccordionContext";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useTranslation } from "react-i18next";
function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div onClick={decoratedOnClick}>
      {isCurrentEventKey ? (
        <Image
          src={process.env.PUBLIC_URL + "/Images/minus.svg"}
          alt="minus"
          width={24}
          height={24}
        />
      ) : (
        <Image
          src={process.env.PUBLIC_URL + "/Images/plus.svg"}
          alt="plus"
          width={24}
          height={24}
        />
      )}
    </div>
  );
}
const FAQ = () => {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="container">
          <div className="flex flex-col gap-10">
            <div className={styles.title}>
              <h2>{t("home.TitleFrequentlyAskedQuestions")}</h2>
            </div>
            <Accordion defaultActiveKey="0" className={styles.accordion}>
              <Accordion.Item eventKey="0" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>
                  {" "}
                  <h3>{t("home.TitleHowDoIUseNojom")}</h3>
                  <ContextAwareToggle eventKey="0"></ContextAwareToggle>
                </Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  {t("home.TextHowDoIUseNojom")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>
                  <h3>{t("home.TitleHowDoIFoundInfluencer")}</h3>
                  <ContextAwareToggle eventKey="1"></ContextAwareToggle>{" "}
                </Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  {t("home.TextHowDoIFoundInfluencer")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>
                  <h3>{t("home.TitleWhatIsInfluencerMarketing")}</h3>
                  <ContextAwareToggle eventKey="2"></ContextAwareToggle>
                </Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  {t("home.TextWhatIsInfluencerMarketing")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>
                  <h3>
                    {" "}
                    {t(
                      "home.TitleWhatAreTheBenefitsOfInfluencerMarketingForMyBrand"
                    )}
                  </h3>
                  <ContextAwareToggle eventKey="3"></ContextAwareToggle>
                </Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  {t(
                    "home.TextWhatAreTheBenefitsOfInfluencerMarketingForMyBrand"
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>
                  <h3>
                    {t(
                      "home.TitleWhatAreTheDifferentTypesOfInfluencerMarketingCampaigns"
                    )}
                  </h3>
                  <ContextAwareToggle eventKey="4"></ContextAwareToggle>
                </Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  {t(
                    "home.TextWhatAreTheDifferentTypesOfInfluencerMarketingCampaigns"
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>
                  <h3>{t("home.TitleHowMuchDoesInfluencerMarketingCost")}</h3>
                  <ContextAwareToggle eventKey="5"></ContextAwareToggle>
                </Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  {t("home.TextHowMuchDoesInfluencerMarketingCost")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};
export default FAQ;
