// @ts-nocheck
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import { useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import ScrollToTop from "./utils/scrollToTop";
import { hotjar } from "react-hotjar";
import "./fonts/stylesheet.css";
import MetaDecorator from "./utils/metaDecorator";
import Router from "./routes/Router";
import React from "react";
const App = () => {
  const [isProfile, setIsProfile] = useState(false);
  const language = localStorage.getItem("language");
  const [lang, setLang] = useState("");
  const dir = localStorage.getItem("dir");
  useLayoutEffect(() => {
    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
      rootHtml.setAttribute("dir", dir);
    }
  }, []);
  useEffect(() => {
    // @ts-ignore
    hotjar.initialize(3889836, 6);
  }, []);
  return (
    <>
      {/* {(lang === "/en" || lang === "") && (
        <MetaDecorator
          title="nojom app | all arab stars, celebrities, and influence in one app"
          description="nojom app is a platform for stars where brands can find, compare, chat with, and work with Arab influencers and celebrities"
          imageUrl="https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/test116.png"
          imageAlt="LogoImage"
        />
      )}
      {lang && (
        <MetaDecorator
          title="تطبيق نجوم | مشاهير العرب في تطبيق واحد"
          description="تطبيق نجوم يمكن الشركات من إيجاد والتواصل مع نجوم ومشاهير العرب والعمل معهم"
          imageUrl="https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/test116.png"
          imageAlt="LogoImage"
        />
      )} */}
      <Header isProfile={isProfile} />
      <ScrollToTop />
      <Router setIsProfile={setIsProfile} setLang={setLang} />
      <Footer isProfile={isProfile} setLang={setLang} lang={language} />
    </>
  );
};
export default App;
