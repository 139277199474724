import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import './ContactUs.css';
import {addContactForm} from './action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

const ContactUs = ({ setIsProfile }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    const onSubmit = async(e) => {
        e.preventDefault(); 

        
        if (!name || !email || !msg) {
            alert("Please fill in all fields.");
            return;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            alert("Invalid email address.");
            return;
        }

        try {

            const formData = new FormData();
            formData.append("name", name);
            formData.append("email", email);
            formData.append("message", msg);

            const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };

                await addContactForm(formData,config).then((res)=>{
                   
                    toast.success(res.data.message);                              
                })

        } catch (error) {
            // console.log("Error fetching contact details:",error);
            toast.error(error)
        }
     
        setName('');
        setEmail('');
        setMsg('');
    }

    useEffect(() => {
        setIsProfile(false);
    }, [setIsProfile]);

    return (
        <>
            <section className="contactus">
                <div className="container">
                    <div className="contactform">
                        <Row>
                            <Col lg={7}>
                                <div className="contactformtitle">
                                    <h3>Get in Touch</h3>
                                    <p>We are here for you. How can we help you?</p>
                                </div>
                                <Form onSubmit={onSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter name" name='name' value={name} onChange={(e) => setName(e.target.value)} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Label>Message</Form.Label>
                                        <textarea placeholder='Enter your message' name='msg' value={msg} onChange={(e)=>setMsg(e.target.value)} required></textarea>
                                    </Form.Group>

                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                            <Col lg={5}>
                                <div className="contactdetail">
                                    <span ><Image src='/Images/contactimg.png' /></span>
                                    <ul className='contact'>
                                        <li><Link to=""><span><Image src='/Images/location.svg'></Image></span>Al Mughrizat, King Abdullah Branch Road, Riyadh</Link></li>
                                        <li><Link to=""><span><Image src='/Images/phone.svg'></Image></span>+966 50 247 5630</Link></li>
                                        <li><Link to=""><span><Image src='/Images/mail.svg'></Image></span>support@nojom.com</Link></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <ToastContainer />
                </div>
            </section>
        </>
    );
}

export default ContactUs;