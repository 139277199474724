
import { Col, Image, Row } from 'react-bootstrap';
import './Aboutus.css';

import React from "react";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';


const StartCampaign = () => {
    return (
        <>
            <section className="startcampaign">
                <div className="container">
                    <div className="startcampaigncontent">
                        <h2>Start your campaign now with your favorite stars</h2>
                        <p>Join us in reshaping the influencer marketing landscape. Explore, connect, and thrive with nojom – Where Influence Meets Impact.</p>
                        <Link to="/">Start Now <Icon icon="system-uicons:arrow-right" width="30px" height="30px" className='ml-5' /></Link>
                    </div>

                </div>
            </section >

        </>
    );
}

export default StartCampaign;
