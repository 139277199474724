import { Col, Form, Image, Row } from "react-bootstrap";
import "./FindtheBest.css";
import { Link } from "react-router-dom/dist";
import { Icon } from "@iconify/react/dist/iconify";
import React from "react";
import styles from "./findTheBest.module.css";
const InfluencerProfile = ({ data, imagePath }) => {
  const formatFollowersCount = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal"
    });
    if (number >= 1_000_000_000) {
      const billionCount = number / 1_000_000_000;
      return `${formatter.format(billionCount)}B`;
    } else if (number >= 1_000_000) {
      const millionCount = number / 1_000_000;
      return `${formatter.format(millionCount)}M`;
    } else if (number >= 1_000) {
      const thousandCount = number / 1_000;
      return `${formatter.format(thousandCount)}K`;
    } else {
      return formatter.format(number);
    }
  };
  return (
    <>
      <Link
        to={`/${data?.username}`}
        className={`${styles.influencerdetail} flex flex-col gap-2`}
      >
        <i>
          <Image src={`${imagePath}${data?.profileimg}`} />
        </i>
        <h3>
          {data?.first_name}
          {data?.first_name && data?.last_name ? " - " : ""}
          {data?.last_name} <Image src="/Images/tick.svg" className="m-0" />
        </h3>
        {data?.skills?.length > 0 && (
          <p>
            {data?.skills?.map((item, index) => {
              if (item.name) {
                return index === data?.skills?.length - 1
                  ? item.name
                  : item.name + " / ";
              }
            })}
          </p>
        )}

        <ul className={`${styles.socialmedia}`}>
          {data?.social_platforms
            ?.sort((a, b) => parseFloat(b.followers) - parseFloat(a.followers))
            .map(
              (item, index) =>
                index < 3 && (
                  <li>
                    <span>
                      <Image src={item.filename_gray} />
                    </span>{" "}
                    {item.followers > 0 && formatFollowersCount(item.followers)}
                  </li>
                )
            )}
          {/* <li><Image src="/Images/twitter.svg" /> 3.2M
                    </li>
                    <li><Image src="/Images/youtube.svg" /> 2.5M
                    </li> */}
        </ul>
        {(data?.min_price > 0 || data?.max_price > 0) && (
          <h5>
            SAR {data?.min_price} - SAR {data?.max_price} <span>/Ads</span>
          </h5>
        )}
      </Link>
    </>
  );
};
export default InfluencerProfile;
