// @ts-nocheck
// import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';

// const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => {
//     console.log(title,description, imageUrl, imageAlt)
//     useEffect(() => {
//         document.title = title;

//         const descriptionMeta = document.querySelector('meta[name="description"]');
//         if (descriptionMeta) {
//             descriptionMeta.setAttribute('content', description);
//         }

//         const ogTitleMeta = document.querySelector('meta[property="og:title"]');
//         if (ogTitleMeta) {
//             ogTitleMeta.setAttribute('content', title);
//         }

//         const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
//         if (ogDescriptionMeta) {
//             ogDescriptionMeta.setAttribute('content', description);
//         }

//         const ogImageMeta = document.querySelector('meta[property="og:image"]');
//         if (ogImageMeta) {
//             ogImageMeta.setAttribute('content', imageUrl);
//         }

//         const ogImageAltMeta = document.querySelector('meta[property="og:image:alt"]');
//         if (ogImageAltMeta) {
//             ogImageAltMeta.setAttribute('content', imageAlt);
//         }

//         const ogUrlMeta = document.querySelector('meta[property="og:url"]');
//         if (ogUrlMeta) {
//             ogUrlMeta.setAttribute('content', window.location.href);
//         }
//         // Add more metadata updates as needed
//     }, [title, description, imageUrl, imageAlt]);

//     // Return null if metadata is not available yet
//     if (!title || !description || !imageUrl || !imageAlt) {
//         return null;
//     }

//     return (
//         <Helmet>
//             <title>{title}</title>
//             <meta property="og:title" content={title} />
//             <meta name="description" content={description} />
//             <meta property="og:description" content={description} />
//             <meta property="og:image" content={imageUrl} />
//             <meta property="og:image:alt" content={imageAlt} />
//             <meta property="og:url" content={window.location.href} />
//         </Helmet>
//     );
// };

// export default MetaDecorator;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => {
  console.log(title, description, imageUrl, imageAlt);
  useEffect(() => {
    const updateMetadata = () => {
      document.title = title;
      document.description = description;
      document.imageUrl = imageUrl;
      document.imageAlt = imageAlt;

      const descriptionMeta = document.querySelector(
        'meta[name="description"]'
      );
      if (descriptionMeta) {
        descriptionMeta.setAttribute("content", description);
      }

      const ogTitleMeta = document.querySelector('meta[property="og:title"]');
      if (ogTitleMeta) {
        ogTitleMeta.setAttribute("content", title);
      }

      const ogDescriptionMeta = document.querySelector(
        'meta[property="og:description"]'
      );
      if (ogDescriptionMeta) {
        ogDescriptionMeta.setAttribute("content", description);
      }

      const ogImageMeta = document.querySelector('meta[property="og:image"]');
      if (ogImageMeta) {
        ogImageMeta.setAttribute("content", imageUrl);
      }

      const ogImageAltMeta = document.querySelector(
        'meta[property="og:image:alt"]'
      );
      if (ogImageAltMeta) {
        ogImageAltMeta.setAttribute("content", imageAlt);
      }

      const ogUrlMeta = document.querySelector('meta[property="og:url"]');
      if (ogUrlMeta) {
        ogUrlMeta.setAttribute("content", window.location.href);
      }
    };

    if (document.readyState === "complete") {
      updateMetadata();
    } else {
      window.addEventListener("load", updateMetadata);
    }

    return () => {
      window.removeEventListener("load", updateMetadata);
    };
  }, [title, description, imageUrl, imageAlt]);

  if (!title || !description || !imageUrl || !imageAlt) {
    return null;
  }

  return (
    <Helmet
      onChangeClientState={(state) => {
        const ogDescriptionMeta = document.querySelector(
          'meta[property="og:description"]'
        );
        if (ogDescriptionMeta) {
          ogDescriptionMeta.setAttribute("content", description);
        }
      }}
    >
      <title>{title}</title>
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta name="description" content={description} data-react-helmet="true" />
      <meta
        property="og:description"
        content={description}
        data-react-helmet="true"
      />
      <meta property="og:image" content={imageUrl} data-react-helmet="true" />
      <meta
        property="og:image:alt"
        content={imageAlt}
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        content={window.location.href}
        data-react-helmet="true"
      />
    </Helmet>
  );
};

export default MetaDecorator;
