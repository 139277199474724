// @ts-nocheck
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
const UserProfileMainHeader = ({
  username,
  setLang,
  handleShowBrand,
  handleCopy,
  copied
}) => {
  const navigate = useNavigate();
  const language = localStorage.getItem("language");
  const { i18n } = useTranslation();
  const handleTogglelanguage = () => {
    const root = document.getElementById("root-html");
    if (language === "ar") {
      root.setAttribute("dir", "ltr");
      localStorage.setItem("dir", "ltr");
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    } else {
      root.setAttribute("dir", "rtl");
      localStorage.setItem("dir", "rtl");
      localStorage.setItem("language", "ar");
      i18n.changeLanguage("ar");
    }
  };
  return (
    <header className="profileheader !absolute w-full flex justify-center">
      <Navbar expand="false" className="">
        <Container>
          <Nav.Link
            to=""
            className="languges m-lg-0 langugesheager-profile"
            onClick={handleTogglelanguage}
          >
            {language === "en" ? "AR" : "EN"}
          </Nav.Link>
          <Link
            to="#"
            title={copied ? "Copied" : "Copy"}
            className="shareicon"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default behavior of the link
              handleCopy();
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 12.5C2.5 14.857 2.5 16.0355 3.23223 16.7678C3.96447 17.5 5.14298 17.5 7.5 17.5H12.5C14.857 17.5 16.0355 17.5 16.7678 16.7678C17.5 16.0355 17.5 14.857 17.5 12.5"
                stroke="#1C274C"
                stroke-opacity="0.7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.0003 13.3333V2.5M10.0003 2.5L13.3337 6.14583M10.0003 2.5L6.66699 6.14583"
                stroke="#1C274C"
                stroke-opacity="0.7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </Container>
      </Navbar>
    </header>
  );
};
export default UserProfileMainHeader;
