// @ts-nocheck
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import "./UserProfile.css";
import React, { Fragment, useEffect, useState, useTransition } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "component/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { getAgentCompanies, getAgentStore, getAgentPartners } from "./action";
import ModalDetail from "../../component/Header/ModalDetail";
import MetaDecorator from "../../utils/metaDecorator";
import UserMainInfo from "./UserMainInfo";
import UserMainAgency from "./UserMainAgency";
import { getAgentByUsername } from "./action";
import UserMainPortfolio from "./UserMainPortfolio";
import UserMainAgentCompanies from "./UserMainAgentCompanies";
import UserProfileMainHeader from "./UserProfileMainHeader";
import UserMainStores from "./UserMainStores";
import UserMainPartners from "./UserMainPartners";
import UserMainProducts from "./UserMainProducts";
import UserMainOverview from "./UserMainOverview";
import { useTranslation } from "react-i18next";
import ModalComponent from "component/Modal/Modal-Component";
import UserMainMyYoutube from "./UserMainMyYoutube";
const Profile = ({ setIsProfile, setLang }) => {
  const [starDetails, setStarDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [showStar, setShowStar] = useState(false);
  const handleCloseBrand = () => setShowBrand(false);
  const handleShowBrand = () => setShowBrand(true);
  const handleCloseStar = () => setShowStar(false);
  const handleShowStar = () => setShowStar(true);
  const [user, setUser] = useState({});
  const { username } = useParams();
  const { i18n } = useTranslation();
  const getUserInfo = async () => {
    await getAgentByUsername(username).then((res) => {
      const data = jwtDecode(res.data.data);
      setUser(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  useEffect(() => {
    setIsProfile(true);
  }, [user?.id]);
  const handleCopy = () => {
    navigator.clipboard
      .writeText(
        `https://endearing-syrniki-85ebd9.netlify.app/en/${starDetails?.username}`
      )
      .then(() => {
        setCopied(true);
        toast.success(
          <div>
            <span>Copied Current URL</span>
            <ShareIconContainer />
          </div>
        );
      })
      .catch((error) => {
        console.error("Error copying text:", error);
        toast.error("An error occurred while copying link. Please try again!");
      });
  };
  return (
    <>
      <MetaDecorator
        title={i18n.language === "ar" ? user?.last_name : user?.first_name}
        imageUrl={`${user?.path}${user?.profile_pic}`}
        imageAlt={"ProfileImage"}
        description={
          i18n.language === "ar"
            ? `صفحة حسابات تواصل ${user?.last_name}`
            : `${user?.first_name} Social accounts page`
        }
      />
      <Fragment>
        <UserProfileMainHeader
          handleShowBrand={handleShowBrand}
          handleCopy={handleCopy}
          username={starDetails?.username}
          copied={copied}
        />
        <section className="profile-main sm:!pt-[100px]">
          <UserMainInfo
            handleShowBrand={handleShowBrand}
            handleShowStar={handleShowStar}
          />
          <UserMainOverview />
          <UserMainPortfolio id={user?.id} />
          <UserMainAgentCompanies id={user?.id} />
          <UserMainStores id={user?.id} />
          <UserMainProducts id={user?.id} />
          <UserMainMyYoutube id={user?.id} />
          <UserMainPartners id={user?.id} />
          <UserMainAgency id={user?.id} />
          <SectionFooter handleShowBrand={handleShowBrand} />
        </section>
        <ModalComponent
          isOpen={showBrand}
          openModal={handleShowBrand}
          closeModal={handleCloseBrand}
          title={"Brand"}
        />
        <ModalComponent
          isOpen={showStar}
          openModal={handleShowStar}
          closeModal={handleCloseStar}
          title={"Star"}
        />
        <ToastContainer
          position="bottom-center"
          autoClose={5000} // Close after 1 minute
          hideProgressBar // Hide progress bar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Fragment>
    </>
  );
};
export default Profile;
const SectionFooter = ({ handleShowBrand }) => {
  return (
    <>
      <Link
        className="btn btn-gray btn-profile-build w-100 w-md-50 !rounded-2xl !py-3 text-center"
        to="#"
        onClick={handleShowBrand}
      >
        Open profile in nojom app
      </Link>
      <p className="profiletext !items-center">
        <span className="me-1">
          <Link to={"/en"}>
            <Image src="/Images/profilelogo.svg" />
          </Link>
        </span>{" "}
        is only for verified stars.
      </p>
    </>
  );
};
const ShareIconContainer = () => (
  <Link to="#" className="shareicon">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 12.5C2.5 14.857 2.5 16.0355 3.23223 16.7678C3.96447 17.5 5.14298 17.5 7.5 17.5H12.5C14.857 17.5 16.0355 17.5 16.7678 16.7678C17.5 16.0355 17.5 14.857 17.5 12.5"
        stroke="#1C274C"
        stroke-opacity="0.7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0003 13.3333V2.5M10.0003 2.5L13.3337 6.14583M10.0003 2.5L6.66699 6.14583"
        stroke="#1C274C"
        stroke-opacity="0.7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </Link>
);
