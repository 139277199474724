// @ts-nocheck
import { Col, Form, Image, Row } from "react-bootstrap";
import styles from "./servingBoth.module.css";
import { Link } from "react-router-dom/dist";
import { Icon } from "@iconify/react/dist/iconify";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalDetail from "../../Header/ModalDetail";
import React from "react";
import { useTranslation } from "react-i18next";
import ModalComponent from "component/Modal/Modal-Component";
const ServingBothSide = () => {
  const [showBrand, setShowBrand] = useState(false);
  const [showStar, setShowStar] = useState(false);
  const handleCloseBrand = () => setShowBrand(false);
  const handleShowBrand = () => setShowBrand(true);
  const handleCloseStar = () => setShowStar(false);
  const handleShowStar = () => setShowStar(true);
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="container">
          <div className="flex flex-col gap-16">
            <div className={styles.findmoreinfluence}>
              <h2>{t("home.TitleHowToserve")}</h2>
            </div>
            <Row className="sm:gap-16">
              <Col md={6} lg={6} xs={12}>
                <div
                  className={`${styles.bussinesworkdetail} sm:w-full md:w-full`}
                >
                  <div className="flex justify-center items-center w-full">
                    <span>
                      <Image
                        src="/Images/forbrand.svg"
                        width={140}
                        height={140}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col gap-7">
                    <div className={styles.subTitle}>
                      {" "}
                      <h4>{t("TextForBrands")}</h4>
                    </div>
                    <div>
                      <ul className={`flex flex-col gap-4 ${styles.list}`}>
                        <li className="flex items-center gap-3">
                          <span>
                            <Image src="/Images/bluetick.svg"></Image>
                          </span>
                          {t("home.TextHigherROI")}
                        </li>
                        <li className="flex items-center gap-3">
                          <span>
                            <Image src="/Images/bluetick.svg"></Image>
                          </span>
                          {t("home.TextSaveMoneyWhenHiring")}
                        </li>
                        <li className="flex items-center gap-3">
                          <span>
                            <Image src="/Images/bluetick.svg"></Image>
                          </span>
                          {t("home.TextAllCampaigns")}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <Link
                        to=""
                        className={styles.applynowbtn}
                        variant="primary"
                        onClick={handleShowBrand}
                      >
                        {t("TextApplyNow")}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} xs={12}>
                <div className={`${styles.bussinesworkdetail}`}>
                  <div className="flex justify-center items-center w-full">
                    <span>
                      <Image
                        src="/Images/forstar.svg"
                        width={140}
                        height={140}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col gap-7">
                    <div className={styles.subTitle}>
                      {" "}
                      <h4>{t("TextForStars")}</h4>
                    </div>
                    <div>
                      <ul className={`flex flex-col gap-4 ${styles.list}`}>
                        <li className="flex items-center gap-3">
                          <span>
                            <Image src="/Images/bluetick.svg"></Image>
                          </span>
                          {t("home.TextOneLinkToShow")}
                        </li>
                        <li className="flex items-center gap-3">
                          <span>
                            <Image src="/Images/bluetick.svg"></Image>
                          </span>
                          {t("home.TextEarnMoney")}
                        </li>
                        <li className="flex items-center gap-3">
                          <span>
                            <Image src="/Images/bluetick.svg"></Image>
                          </span>
                          {t("home.TextManageBuisness")}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <Link
                        to=""
                        className={styles.applynowbtn}
                        variant="primary"
                        onClick={handleShowStar}
                      >
                        {t("TextApplyNow")}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <ModalComponent
        isOpen={showBrand}
        openModal={handleShowBrand}
        closeModal={handleCloseBrand}
        title={"Brand"}
      />
      <ModalComponent
        isOpen={showStar}
        openModal={handleShowStar}
        closeModal={handleCloseStar}
        title={"Star"}
      />
    </>
  );
};
export default ServingBothSide;
