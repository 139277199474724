// @ts-nocheck
import React, { useState, useTransition, useEffect, Fragment } from "react";
import { getAgentCompanies } from "./action";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Button,
  Modal,
  Row
} from "react-bootstrap";
import SectionTitle from "./SectionTitle";
import Loader from "component/Loader/Loader";
import Sperarator from "./Seperator";
function UserMainAgentCompanies({ id }) {
  const [userAgentCompanies, setUserAgentCompanies] = useState({
    data: [],
    page_no: 1,
    loading: true
  });
  const getUserAgentCompanies = async () => {
    setUserAgentCompanies({ ...userAgentCompanies, loading: true });
    await getAgentCompanies(id, userAgentCompanies.page_no)
      .then((res) => {
        const data = jwtDecode(res.data.data);
        setUserAgentCompanies({
          ...userAgentCompanies,
          data: data,
          loading: false
        });
      })
      .catch(() => {
        setUserAgentCompanies({
          ...userAgentCompanies,
          loading: false
        });
      });
  };
  useEffect(() => {
    getUserAgentCompanies();
  }, [userAgentCompanies.page_no]);
  const { i18n } = useTranslation();
  const [_, startTransition] = useTransition();
  const handleNext = () => {
    startTransition(() => {
      setUserAgentCompanies((prevState) => ({
        ...prevState,
        page_no: prevState.page_no + 1
      }));
    });
  };
  const handlePrev = () => {
    if (userAgentCompanies?.page_no >= 2) {
      startTransition(() => {
        setUserAgentCompanies((prevState) => ({
          ...prevState,
          page_no: prevState.page_no - 1
        }));
      });
    }
  };
  const ItemsAmount = userAgentCompanies?.page_no * 6;
  return (
    <Fragment>
      {userAgentCompanies?.data?.length === 0 ? null : (
        <Fragment>
          <Sperarator />
          <section className="section">
            <SectionTitle
              title={"Work with"}
              handleNext={handleNext}
              handlePrev={handlePrev}
              isNextDisabled={userAgentCompanies?.data?.count <= ItemsAmount}
              isPrevDisabled={userAgentCompanies?.page_no <= 1}
            />
            <div className="work-with-items">
              <Row className="gy-4">
                {userAgentCompanies?.data?.data?.map((item, index) => (
                  <Col xs="6" md="6" key={index}>
                    <div className="work-with-item rtl:!flex-row">
                      <div className="work-with-item-info rtl:!flex-row">
                        <div className="image-container">
                          <Image
                            src={`${userAgentCompanies?.data.path}${item?.filename}`}
                            className="rounded"
                          />
                          <div className="contract-status">
                            <p>contract</p>
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div>
                            <h4>
                              {i18n.language === "ar"
                                ? item?.name_ar
                                : item?.name}
                            </h4>
                          </div>
                          <div className="work-with-item-details">
                            <p>
                              <span>{item?.times}</span> Times
                            </p>
                          </div>
                          <div className="work-with-item-date flex gap-1 items-center">
                            <div>
                              <p>5-2022</p>
                            </div>
                            {/* <div>
                              <Image
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Images/grey-arrow.svg"
                                }
                              />
                            </div>
                            <div>
                              <p>3-2022</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
}
export default UserMainAgentCompanies;
