// @ts-nocheck
import { Col, Form, Image, Row } from "react-bootstrap";
import "./Nojom.css";
import { Link } from "react-router-dom/dist";
import { Icon } from "@iconify/react/dist/iconify";
import styles from "./nojom.module.css";
import { useTranslation } from "react-i18next";
const Nojom = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="nojomsec">
        <div className="container">
          <div className="flex flex-col gap-10">
            <div className={styles.title}>
              <h2>{t("home.TitleThisIsNojom")}</h2>
            </div>
            <Row className="sm:gap-5">
              <Col lg={4} md={4} xs={12}>
                <div className={styles.nojom}>
                  <h3>{t("home.TitleElevatesBranding")}</h3>
                  <p>{t("home.TextElevatesBranding")}</p>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12}>
                <div className={styles.nojom}>
                  <h3>{t("home.TitleEmpowersLucrative")}</h3>
                  <p>{t("home.TextEmpowersLucratives")}</p>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12}>
                <div className={styles.nojom}>
                  <h3>{t("home.TitleFacilitiesBranding")}</h3>
                  <p>{t("home.TextFacilitiesBranding")}</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};
export default Nojom;
